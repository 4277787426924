import { App } from 'lib/app'
import * as normalize from 'lib/normalize'
import { IServerData } from 'lib/typing'
import { createElement } from 'react'
import ReactDom from 'react-dom'

export function init(data?: IServerData) {
    if (!data) {
        return
    }
    const el = document.createElement('div')
    el.id = 'app'
    document.body.appendChild(el)
    const app = ReactDom.render(
        createElement(App, {
            bootstrap: normalize.fromServer(data),
        }),
        el
    )
    return app
}
