import styles from 'apps/modal/less/centered.less'
import Modal from 'apps/modal/modal'
import { noop } from 'lodash-es'
import PropTypes from 'prop-types'
import { Component } from 'react'

export default class ModalCentered extends Component {
    static propTypes = {
        children: PropTypes.any,
        onClose: PropTypes.func,
        preventCloseOnEsc: PropTypes.bool,
        noBackground: PropTypes.bool,
    }

    static defaultProps = {
        onClose: noop,
    }

    render() {
        return (
            <Modal
                onClose={this.props.onClose}
                preventCloseOnEsc={this.props.preventCloseOnEsc}
                noBackground={this.props.noBackground}
            >
                <div className={styles.centered}>{this.props.children}</div>
            </Modal>
        )
    }
}
