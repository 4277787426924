import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'

export enum ERRORS {
    autoProvisionSso = 'authorize.sso_auto_provision',
    badRequest = 'api.bad_request',
    consentRequired = 'authorize.consent_required',
    emptyEmail = 'empty_email',
    invalidClient = 'authorize.invalid_client',
    invalidCredentials = 'authorize.invalid_credentials',
    invalidOtp = 'authorization:invalid_one_time_password',
    invalidSubdomain = 'invalid_subdomain',
    invalidToken = 'api.invalid_token',
    isSsoUser = 'authorize.is_sso_user',
    otpBlock = 'authorize.two_factor_block',
    otpRequired = 'authorize.totp_required',
    passwordForbiddenContent = 'api.invalid_password.forbidden_content',
    passwordLoginLocked = 'authorize.password_login_locked',
    passwordMissingLowercase = 'api.invalid_password.missing_lowercase',
    passwordMissingNumber = 'api.invalid_password.missing_number',
    passwordMissingSymbol = 'api.invalid_password.missing_symbol',
    passwordMissingUppercase = 'api.invalid_password.missing_uppercase',
    passwordReused = 'api.invalid_password.reused',
    passwordTooShort = 'api.invalid_password.too_short',
    rateLimitExceeded = 'api.rate_limit_exceeded',
    ssoGeneralError = 'saml2.invalid_user',
    ssoInvalidSignature = 'saml2.invalid_signature',
    ssoMissingEmailAttribute = 'saml2.missing_email_attribute',
    tenantIdRequired = 'authorize.tenant_id_required',
    tenantResetPasswordRequired = 'authorize.tenant_password_reset_required',
    unknown = 'unknown_error',
}
export const DEFAULT_ERROR_MESSAGE = i18next.t('PERC_An_Error_Occurred', {
    defaultValue: enUS['PERC_An_Error_Occurred'],
})
export const ERROR_MESSAGE_MAP = {
    [ERRORS.emptyEmail]: i18next.t('PERC_Pident_Empty_Email', {
        defaultValue: enUS['PERC_Pident_Empty_Email'],
    }),
    [ERRORS.invalidCredentials]: i18next.t('PERC_Pident_Invalid_Credentials', {
        defaultValue: enUS['PERC_Pident_Invalid_Credentials'],
    }),
    [ERRORS.invalidSubdomain]: i18next.t('PERC_Pident_Invalid_Subdomain', {
        defaultValue: enUS['PERC_Pident_Invalid_Subdomain'],
    }),
    [ERRORS.invalidToken]: i18next.t('PERC_Pident_Invalid_Token', {
        defaultValue: enUS['PERC_Pident_Invalid_Token'],
    }),
    [ERRORS.otpRequired]: i18next.t('PERC_Pident_Two_Factor', {
        defaultValue: enUS['PERC_Pident_Two_Factor'],
    }),
    [ERRORS.passwordForbiddenContent]: i18next.t('PERC_Pident_Generic_Password', {
        defaultValue: enUS['PERC_Pident_Generic_Password'],
    }),
    [ERRORS.passwordLoginLocked]: i18next.t('PERC_Pident_Login_Locked', {
        defaultValue: enUS['PERC_Pident_Login_Locked'],
    }),
    [ERRORS.passwordMissingLowercase]: i18next.t('PERC_Pident_Password_Missing_Lowercase', {
        defaultValue: enUS['PERC_Pident_Password_Missing_Lowercase'],
    }),
    [ERRORS.passwordMissingNumber]: i18next.t('PERC_Pident_Password_Missing_Number', {
        defaultValue: enUS['PERC_Pident_Password_Missing_Number'],
    }),
    [ERRORS.passwordMissingSymbol]: i18next.t('PERC_Pident_Password_Missing_Symbol', {
        defaultValue: enUS['PERC_Pident_Password_Missing_Symbol'],
    }),
    [ERRORS.passwordMissingUppercase]: i18next.t('PERC_Pident_Password_Missing_Uppercase', {
        defaultValue: enUS['PERC_Pident_Password_Missing_Uppercase'],
    }),
    [ERRORS.passwordReused]: i18next.t('PERC_Pident_Password_Reused', {
        defaultValue: enUS['PERC_Pident_Password_Reused'],
    }),
    [ERRORS.passwordTooShort]: i18next.t('PERC_Pident_Password_Short', {
        defaultValue: enUS['PERC_Pident_Password_Short'],
    }),
    [ERRORS.rateLimitExceeded]: i18next.t('PERC_Pident_Rate_Limit_Exceeded', {
        defaultValue: enUS['PERC_Pident_Rate_Limit_Exceeded'],
    }),
    [ERRORS.ssoGeneralError]: i18next.t('PERC_Pident_SSO_Error', {
        defaultValue: enUS['PERC_Pident_SSO_Error'],
    }),
    [ERRORS.ssoInvalidSignature]: i18next.t('PERC_Pident_SSO_Invalid_Signature', {
        defaultValue: enUS['PERC_Pident_SSO_Invalid_Signature'],
    }),
    [ERRORS.ssoMissingEmailAttribute]: i18next.t('PERC_Pident_SSO_Missing_Signature', {
        defaultValue: enUS['PERC_Pident_SSO_Missing_Signature'],
    }),
    [ERRORS.tenantResetPasswordRequired]: i18next.t('PERC_Pident_Tenant_Password_Reset', {
        defaultValue: enUS['PERC_Pident_Tenant_Password_Reset'],
    }),
}
export const SAML2_REQUEST_URL = '/auth/saml2/request'
export enum SESSIONS {
    long = 'long-lived',
    short = 'short-lived',
}
export const MAX_LOGIN_ATTEMPTS = 5
export const IDP_APP_LOGIN_URL = '/auth/redirect-idp-app'
