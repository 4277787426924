/* eslint @percolate/no-jsx-id-attrs:0 */
import { IInputProps, Input } from '@percolate/ui'
import { Field, IProps as IFieldProps } from 'lib/components/field'
import styles from 'lib/components/input_field.less'
import { isEmpty, isString, uniqueId } from 'lodash'
import * as React from 'react'

export interface IProps extends Pick<IFieldProps, 'label'> {
    autoFocus?: boolean
    disabled?: boolean
    endAdornment?: IInputProps['endAdornment']
    error?: string
    onBlur?(): void
    onChange(value: string): void
    placeholder?: string
    preserveFocusOnUpdate?: boolean
    type: 'email' | 'password' | 'otp' | 'text'
    value: string
    id?: string
}

export class InputField extends React.Component<IProps> {
    focusRef = React.createRef<HTMLInputElement>()

    componentDidMount() {
        if (this.props.autoFocus) {
            this.onFocus()
        }
    }

    componentDidUpdate({ value }: IProps) {
        if (this.props.preserveFocusOnUpdate && value === this.props.value) {
            this.onFocus()
        }
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(e.currentTarget.value)
    }

    onFocus = () => {
        if (this.focusRef.current) {
            this.focusRef.current.focus()
        }
    }

    render() {
        const {
            autoFocus,
            disabled,
            endAdornment,
            error,
            label,
            onBlur,
            placeholder,
            type,
            value,
            id = uniqueId('input-field-'),
        } = this.props
        return (
            <Field label={label} error={!isEmpty(error)} errorMessage={error} htmlFor={id}>
                <Input
                    autoFocus={autoFocus}
                    endAdornment={endAdornment}
                    error={isString(error)}
                    className={styles.input}
                    disabled={disabled}
                    onBlur={onBlur}
                    onChange={this.onChange}
                    placeholder={placeholder}
                    ref={this.focusRef}
                    value={value}
                    id={id}
                    {...(() => {
                        switch (type) {
                            case 'email':
                                return {
                                    type: 'email',
                                }
                            case 'otp':
                                return {
                                    autoComplete: 'off',
                                    pattern: '[0-9 ]*',
                                    type: 'number',
                                }
                            case 'password':
                                return {
                                    type: 'password',
                                }
                            case 'text':
                                return {
                                    type: 'text',
                                }
                        }
                    })()}
                />
            </Field>
        )
    }
}
