import classNames from 'classnames'
import styles from 'lib/components/loading_icon.less'

export interface IProps {
    className?: string
}

export function LoadingIcon({ className }: IProps) {
    return (
        <div className={classNames(styles.root, className)}>
            <svg width="48" height="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12A4 4 0 0 1 17 12" />
                <circle cx="12" cy="12" r="10" />
            </svg>
        </div>
    )
}
