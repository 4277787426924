import * as React from 'react'

export interface IProps {
    children: React.ReactNode
    onSubmit(): void
}

export class Form extends React.Component<IProps> {
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        this.props.onSubmit()
    }

    render() {
        return (
            <form action="#" onSubmit={this.onSubmit}>
                {this.props.children}
            </form>
        )
    }
}
