/* eslint no-restricted-properties: "off" */
/** simplified location with mocking support */
export const location = {
    /* istanbul ignore next */
    assign(url: string) {
        return window.location.assign(url)
    },
    get href() {
        return window.location.href
    },
    get host() {
        return window.location.host
    },
    get origin() {
        return window.location.origin
    },
    get pathname() {
        return window.location.pathname
    },
    get search() {
        return window.location.search
    },
    get protocol() {
        return window.location.protocol
    },
}
