import { Button, LinkButton } from '@percolate/ui'
import classNames from 'classnames'
import { location } from 'common/location'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { Error } from 'lib/components/error'
import { Form } from 'lib/components/form'
import { InputField } from 'lib/components/input_field'
import { RedirectToIdp } from 'lib/components/redirect_to_idp'
import { Typography } from 'lib/components/typography'
import { IContext, IEventStartOrigin, IEventSubmitEmail } from 'lib/typing'
import utilStyles from 'lib/util.less'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<
        IContext,
        'clientId' | 'email' | 'error' | 'overrideSuccessRedirectUrl' | 'state' | 'idpAppId'
    >
    matches(stateValue: StateValue): boolean
    startOrigin(e: IEventStartOrigin): void
    submitEmail(e: IEventSubmitEmail): void
}
interface IState {
    email: string
    password: string
}

export class Email extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            email: '',
            password: '',
        }
    }

    onChangeEmail = (email: string) => {
        this.setState({ email })
    }

    onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: e.currentTarget.value })
    }

    onStartOrigin = () => {
        this.props.startOrigin({ type: 'startOrigin' })
    }

    onSubmit = () => {
        this.props.submitEmail({
            type: 'submitEmail',
            payload: {
                email: this.state.email,
                ...(!isEmpty(this.state.password)
                    ? {
                          password: this.state.password,
                      }
                    : undefined),
            },
        })
    }

    render() {
        const { context, matches } = this.props
        const disabled = matches({ email: 'submitting' }) || matches({ email: 'redirectingToIdp' })
        return (
            <>
                {matches({ email: 'redirectingToIdp' }) && (
                    <RedirectToIdp
                        clientId={context.clientId}
                        email={context.email}
                        overrideSuccessRedirectUrl={context.overrideSuccessRedirectUrl}
                        state={context.state}
                        idpAppId={context.idpAppId}
                    />
                )}
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Sign_In', { defaultValue: enUS['PERC_Sign_In'] })}
                </Typography>
                <Typography align="center" type="paragraph">
                    {i18next.t('PERC_Sign_In_To', { defaultValue: enUS['PERC_Sign_In_To'] })}
                    <span className={utilStyles.fontWeightBold}>{location.host}</span>.
                </Typography>
                {context.error && <Error error={context.error} />}
                <Form onSubmit={this.onSubmit}>
                    <InputField
                        autoFocus
                        disabled={disabled}
                        label={i18next.t('PERC_Email_Address', { defaultValue: enUS['PERC_Email_Address'] })}
                        onChange={this.onChangeEmail}
                        placeholder="name@company.com"
                        preserveFocusOnUpdate
                        type="email"
                        value={this.state.email}
                    />
                    <Button
                        type="submit"
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        disabled={disabled}
                        label={i18next.t('PERC_Continue', { defaultValue: enUS['PERC_Continue'] })}
                        variant="primary"
                    />
                    {/* capture password from password manager, hide from screen readers */}
                    <input
                        disabled={disabled}
                        onChange={this.onChangePassword}
                        type="password"
                        style={{ display: 'none' }}
                        value={this.state.password}
                    />
                </Form>
                <LinkButton
                    className={classNames(utilStyles.pushTopL, utilStyles.formButton, utilStyles.textButton)}
                    label={i18next.t('PERC_Pident_Different_URL', {
                        defaultValue: enUS['PERC_Pident_Different_URL'],
                    })}
                    onClick={this.onStartOrigin}
                />
            </>
        )
    }
}
