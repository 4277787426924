import styles from 'lib/components/anchor.less'
import * as React from 'react'

export interface IProps {
    children: React.ReactNode
    href: string
}

export function Anchor({ children, href }: IProps) {
    return (
        <a className={styles.root} href={href}>
            {children}
        </a>
    )
}
