import { Button, LinkButton } from '@percolate/ui'
import classNames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { BackButton } from 'lib/components/back_button'
import { Error } from 'lib/components/error'
import { Form } from 'lib/components/form'
import { InputField } from 'lib/components/input_field'
import { LoadingIcon } from 'lib/components/loading_icon'
import { RedirectToIdp } from 'lib/components/redirect_to_idp'
import { RedirectToUrl } from 'lib/components/redirect_to_url'
import { SelectField } from 'lib/components/select_field'
import { Typography } from 'lib/components/typography'
import {
    IContext,
    IEventComplete,
    IEventStartEmail,
    IEventStartResetPassword,
    IEventSubmitPassword,
    IEventSubmitTenantId,
} from 'lib/typing'
import utilStyles from 'lib/util.less'
import { map, orderBy } from 'lodash'
import { Component } from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<
        IContext,
        'clientId' | 'email' | 'error' | 'overrideSuccessRedirectUrl' | 'password' | 'state'
    > &
        Required<Pick<IContext, 'tenantId' | 'tenants'>>
    complete(e: IEventComplete): void
    matches(stateValue: StateValue): boolean
    startEmail(e: IEventStartEmail): void
    startResetPassword(e: IEventStartResetPassword): void
    submitPassword(e: IEventSubmitPassword): void
    submitTenantId(e: IEventSubmitTenantId): void
}
interface IState {
    password: string
    tenantId: string
}

export class Tenant extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            password: props.context.password || '',
            tenantId: props.context.tenantId,
        }
    }

    onChangePassword = (password: string) => {
        this.setState({ password })
    }

    onChangeTenantId = (tenantId: string) => {
        this.props.submitTenantId({
            type: 'submitTenantId',
            payload: {
                tenantId,
            },
        })
        this.setState({
            tenantId,
        })
    }

    onComplete = () => {
        this.props.complete({ type: 'complete' })
    }

    onStartEmail = () => {
        this.props.startEmail({ type: 'startEmail' })
    }

    onStartResetPassword = () => {
        this.props.startResetPassword({ type: 'startResetPassword' })
    }

    onSubmit = () => {
        this.props.submitPassword({
            type: 'submitPassword',
            payload: {
                password: this.state.password,
            },
        })
    }

    render() {
        const { context, matches } = this.props
        if (matches({ tenant: 'error' })) {
            return (
                <>
                    <Typography align="center" type="header1">
                        {i18next.t('PERC_Oops', { defaultValue: enUS['PERC_Oops'] })}
                    </Typography>
                    {context.error && <Error error={context.error} />}
                    <Button
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        label={i18next.t('PERC_Pident_Return_To_Sign_In', {
                            defaultValue: enUS['PERC_Pident_Return_To_Sign_In'],
                        })}
                        onClick={this.onStartEmail}
                        variant="primary"
                    />
                </>
            )
        }
        if (matches({ tenant: 'fetchingTenants' }) || matches({ tenant: 'submittingSelectedTenant' })) {
            return (
                <>
                    <Typography align="center" type="header1">
                        {i18next.t('PERC_Sign_In', { defaultValue: enUS['PERC_Sign_In'] })}
                    </Typography>
                    <Typography align="center" type="paragraph">
                        {i18next.t('PERC_Pident_Loading_Account', {
                            defaultValue: enUS['PERC_Pident_Loading_Account'],
                        })}
                    </Typography>
                    <div className={utilStyles.pushTopXxl}>
                        <LoadingIcon />
                    </div>
                </>
            )
        }
        const disabled =
            matches({
                tenant: { selectedAuthorizedTenant: 'submittingSelectedTenant' },
            }) ||
            matches({
                tenant: { selectedAuthorizedTenant: 'redirectingToApp' },
            }) ||
            matches({
                tenant: { selectedResetPasswordTenant: 'submittingSelectedTenant' },
            }) ||
            matches({
                tenant: { selectedNonSsoTenant: 'submittingSelectedTenant' },
            }) ||
            matches({
                tenant: { selectedSsoTenant: 'submittingSelectedTenant' },
            }) ||
            matches({
                tenant: { selectedAuthorizedTenant: 'redirectingToApp' },
            }) ||
            matches({ tenant: { selectedNonSsoTenant: 'submitting' } }) ||
            matches({ tenant: { selectedNonSsoTenant: 'redirectingToApp' } }) ||
            matches({ tenant: { selectedSsoTenant: 'redirectingToIdp' } })
        return (
            <>
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Sign_In', { defaultValue: enUS['PERC_Sign_In'] })}
                </Typography>
                <Typography align="center" type="paragraph">
                    {i18next.t('PERC_Pident_Select_Organization', {
                        defaultValue: enUS['PERC_Pident_Select_Organization'],
                    })}
                </Typography>
                {context.error && <Error error={context.error} />}
                <SelectField
                    disabled={disabled}
                    label={i18next.t('PERC_Organization', { defaultValue: enUS['PERC_Organization'] })}
                    onChange={this.onChangeTenantId}
                    options={map(
                        orderBy(context.tenants!, ({ name }) => name),
                        ({ id, name }) => ({
                            label: name,
                            value: id,
                        })
                    )}
                    value={this.state.tenantId}
                />
                {(() => {
                    if (matches({ tenant: 'selectedAuthorizedTenant' })) {
                        return (
                            <>
                                {matches({
                                    tenant: { selectedAuthorizedTenant: 'redirectingToApp' },
                                }) && <RedirectToUrl url={context.overrideSuccessRedirectUrl!} />}
                                <Button
                                    className={classNames(
                                        utilStyles.submitButton,
                                        utilStyles.pushTopXxl,
                                        utilStyles.formButton
                                    )}
                                    disabled={disabled}
                                    label={i18next.t('PERC_Continue', {
                                        defaultValue: enUS['PERC_Continue'],
                                    })}
                                    onClick={this.onComplete}
                                    variant="primary"
                                />
                            </>
                        )
                    } else if (matches({ tenant: 'selectedResetPasswordTenant' })) {
                        return (
                            <Button
                                className={classNames(
                                    utilStyles.submitButton,
                                    utilStyles.pushTopXxl,
                                    utilStyles.formButton
                                )}
                                disabled={disabled}
                                label={i18next.t('PERC_Settings_Reset_Password', {
                                    defaultValue: enUS['PERC_Settings_Reset_Password'],
                                })}
                                onClick={this.onStartResetPassword}
                                variant="primary"
                            />
                        )
                    } else if (matches({ tenant: 'selectedNonSsoTenant' })) {
                        return (
                            <>
                                {matches({ tenant: { selectedNonSsoTenant: 'redirectingToApp' } }) && (
                                    <RedirectToUrl url={context.overrideSuccessRedirectUrl!} />
                                )}
                                <Form onSubmit={this.onSubmit}>
                                    <InputField
                                        autoFocus
                                        disabled={disabled}
                                        label={i18next.t('PERC_Password', {
                                            defaultValue: enUS['PERC_Password'],
                                        })}
                                        onChange={this.onChangePassword}
                                        placeholder={i18next.t('PERC_Password', {
                                            defaultValue: enUS['PERC_Password'],
                                        })}
                                        preserveFocusOnUpdate
                                        type="password"
                                        value={this.state.password}
                                    />
                                    <Button
                                        type="submit"
                                        className={classNames(utilStyles.pushTopXxl, utilStyles.formButton)}
                                        disabled={disabled}
                                        label={i18next.t('PERC_Sign_In', {
                                            defaultValue: enUS['PERC_Sign_In'],
                                        })}
                                        variant="primary"
                                    />
                                </Form>
                                <LinkButton
                                    className={classNames(
                                        utilStyles.pushTopL,
                                        utilStyles.formButton,
                                        utilStyles.textButton
                                    )}
                                    label={i18next.t('PERC_Pident_Forgot_Password', {
                                        defaultValue: enUS['PERC_Pident_Forgot_Password'],
                                    })}
                                    onClick={this.onStartResetPassword}
                                />
                            </>
                        )
                    } else if (matches({ tenant: 'selectedSsoTenant' })) {
                        return (
                            <>
                                {matches({ tenant: { selectedSsoTenant: 'redirectingToIdp' } }) && (
                                    <RedirectToIdp
                                        clientId={context.clientId}
                                        email={context.email}
                                        overrideSuccessRedirectUrl={context.overrideSuccessRedirectUrl}
                                        state={context.state}
                                        tenantId={context.tenantId}
                                    />
                                )}
                                <Button
                                    className={classNames(
                                        utilStyles.submitButton,
                                        utilStyles.pushTopXxl,
                                        utilStyles.formButton
                                    )}
                                    disabled={disabled}
                                    label={i18next.t('PERC_Continue', {
                                        defaultValue: enUS['PERC_Continue'],
                                    })}
                                    onClick={this.onComplete}
                                    variant="primary"
                                />
                            </>
                        )
                    }
                })()}
                <BackButton onClick={this.onStartEmail} />
            </>
        )
    }
}
