import { Button, LinkButton } from '@percolate/ui'
import classNames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { BackButton } from 'lib/components/back_button'
import { Error } from 'lib/components/error'
import { Form } from 'lib/components/form'
import { InputField } from 'lib/components/input_field'
import { RedirectToUrl } from 'lib/components/redirect_to_url'
import { Typography } from 'lib/components/typography'
import { IContext, IEventStartEmail, IEventStartResetPassword, IEventSubmitPassword } from 'lib/typing'
import utilStyles from 'lib/util.less'
import { Component } from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<IContext, 'error' | 'overrideSuccessRedirectUrl' | 'password'>
    matches(stateValue: StateValue): boolean
    startEmail(e: IEventStartEmail): void
    startResetPassword(e: IEventStartResetPassword): void
    submitPassword(e: IEventSubmitPassword): void
}
interface IState {
    password: string
}

export class Password extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            password: props.context.password || '',
        }
    }

    onChangePassword = (password: string) => {
        this.setState({ password })
    }

    onStartEmail = () => {
        this.props.startEmail({ type: 'startEmail' })
    }

    onStartResetPassword = () => {
        this.props.startResetPassword({ type: 'startResetPassword' })
    }

    onSubmit = () => {
        this.props.submitPassword({
            type: 'submitPassword',
            payload: {
                password: this.state.password,
            },
        })
    }

    render() {
        const { context, matches } = this.props
        const disabled = matches({ password: 'submitting' }) || matches({ password: 'redirectingToApp' })
        return (
            <>
                {matches({ password: 'redirectingToApp' }) && (
                    <RedirectToUrl url={context.overrideSuccessRedirectUrl!} />
                )}
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Sign_In', { defaultValue: enUS['PERC_Sign_In'] })}
                </Typography>
                {context.error && <Error error={context.error} />}
                <Form onSubmit={this.onSubmit}>
                    <InputField
                        autoFocus
                        disabled={disabled}
                        label={i18next.t('PERC_Password', { defaultValue: enUS['PERC_Password'] })}
                        onChange={this.onChangePassword}
                        placeholder={i18next.t('PERC_Password', { defaultValue: enUS['PERC_Password'] })}
                        preserveFocusOnUpdate
                        type="password"
                        value={this.state.password}
                    />
                    <Button
                        type="submit"
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        disabled={disabled}
                        label={i18next.t('PERC_Sign_In', { defaultValue: enUS['PERC_Sign_In'] })}
                        variant="primary"
                    />
                </Form>
                <LinkButton
                    className={classNames(utilStyles.pushTopL, utilStyles.formButton, utilStyles.textButton)}
                    label={i18next.t('PERC_Pident_Forgot_Password', {
                        defaultValue: enUS['PERC_Pident_Forgot_Password'],
                    })}
                    onClick={this.onStartResetPassword}
                />
                <BackButton onClick={this.onStartEmail} />
            </>
        )
    }
}
