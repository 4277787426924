import { LinkButton } from '@percolate/ui'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { AuthTransportError } from 'lib/auth_transport_error'
import styles from 'lib/components/error.less'
import { DEFAULT_ERROR_MESSAGE, ERROR_MESSAGE_MAP, ERRORS, MAX_LOGIN_ATTEMPTS } from 'lib/constants'
import { get } from 'lodash'

export interface IProps {
    error: Error | AuthTransportError
    resetPassword?(): void
}

export function Error({ error, resetPassword }: IProps) {
    const code = get(error, ['data', 'code'], '')
    let content
    let message = get(ERROR_MESSAGE_MAP, get(error, ['data', 'code'], ''), DEFAULT_ERROR_MESSAGE)
    if (code === ERRORS.invalidCredentials) {
        const remainingLoginAttempts = MAX_LOGIN_ATTEMPTS - get(error, ['data', 'loginAttempts'], 0)
        if (remainingLoginAttempts <= 2) {
            message = i18next.t('PERC_Pident_Login_Attempts', {
                attempts: remainingLoginAttempts,
                defaultValue: enUS['PERC_Pident_Login_Attempts'],
            })
        }
    }
    if (code === ERRORS.invalidToken) {
        content = (
            <div className={styles.text}>
                {i18next.t('PERC_Pident_Activation_Expired', {
                    defaultValue: enUS['PERC_Pident_Activation_Expired'],
                })}
                <LinkButton
                    style={{ textTransform: 'lowercase' }}
                    label={i18next.t('PERC_Pident_Generate_Password_Link', {
                        defaultValue: enUS['PERC_Pident_Generate_Password_Link'],
                    })}
                    onClick={resetPassword}
                />
            </div>
        )
    } else {
        content = <div className={styles.text}>{message}</div>
    }
    return (
        <div className={styles.root}>
            <div className={styles.iconWrapper}>
                <span className={styles.icon} dangerouslySetInnerHTML={{ __html: `&#xe9dc;` }} />
            </div>
            {content}
        </div>
    )
}
