/* eslint @typescript-eslint/class-name-casing: "warn" */
import SmallTooltipContent from 'apps/core/components/small_tooltip_content'
import styles from 'apps/core/less/tooltip.less'
import Popper, { fadeTransition } from 'apps/core/popper'
import TooltipContent from 'apps/core/tooltip_content'
import tooltipHOC from 'apps/core/tooltip_hoc'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Component } from 'react'

const DEFAULT_MAX_WIDTH = 200

export default tooltipHOC(
    class coreTooltip extends Component {
        static propTypes = {
            children: PropTypes.node,
            open: PropTypes.bool,
            display: PropTypes.oneOf(['inline', 'block', 'inline-block', 'flex', 'inline-flex']),
            html: PropTypes.node,
            maxWidth: PropTypes.number,
            fixedWidth: PropTypes.number,
            text: PropTypes.string,
            anchorBottom: PropTypes.bool,
            anchorRight: PropTypes.bool,
            padding: PropTypes.bool,
            small: PropTypes.bool,
            onTriggerMouseMove: PropTypes.func,
            onTriggerMouseLeave: PropTypes.func,
            onTooltipMouseEnter: PropTypes.func,
            onTooltipMouseLeave: PropTypes.func,

            // validation only
            _empty: function (props) {
                if (!props.text && !props.html) {
                    return Error('`text` or `html` is required')
                }
            },
        }

        static defaultProps = {
            display: 'inline-block',
            maxWidth: DEFAULT_MAX_WIDTH,
            padding: true,
        }

        render() {
            const triggerClasses = classNames(styles.trigger, {
                [styles.inline]: this.props.display === 'inline',
                [styles.inlineBlock]: this.props.display === 'inline-block',
                [styles.flex]: this.props.display === 'flex',
            })
            let padding
            if (this.props.padding) {
                if (this.props.text) padding = 'small'
                if (this.props.html) padding = 'large'
            }

            let placement = 'top'
            if (this.props.anchorBottom) {
                placement = 'bottom'
            } else if (this.props.anchorRight) {
                placement = 'right'
            }
            let offset
            if (!this.props.small) {
                offset = this.props.anchorRight ? '10, 0' : '0, 10'
            }
            return (
                <Popper
                    showing={this.props.open}
                    offset={offset}
                    placement={placement}
                    transitionGroupOptions={fadeTransition}
                    arrow={this.props.small}
                    arrowClassName={styles.arrow}
                    unrestrictedHeight
                >
                    <div
                        className={triggerClasses}
                        onMouseMove={this.props.onTriggerMouseMove}
                        onMouseLeave={this.props.onTriggerMouseLeave}
                    >
                        {this.props.children}
                    </div>
                    {this.props.small && this.props.text ? (
                        <SmallTooltipContent text={this.props.text} />
                    ) : (
                        <TooltipContent
                            onMouseEnter={this.props.onTooltipMouseEnter}
                            onMouseLeave={this.props.onTooltipMouseLeave}
                            maxWidth={this.props.maxWidth}
                            width={this.props.fixedWidth}
                            padding={padding}
                        >
                            {this.props.text ? this.props.text : this.props.html}
                        </TooltipContent>
                    )}
                </Popper>
            )
        }
    }
)
