import { Button } from '@percolate/ui'
import classNames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { Anchor } from 'lib/components/anchor'
import { Error } from 'lib/components/error'
import { Form } from 'lib/components/form'
import { InputField } from 'lib/components/input_field'
import { Typography } from 'lib/components/typography'
import { IContext, IEventStartEmail, IEventSubmitResetPasswordEmail } from 'lib/typing'
import utilStyles from 'lib/util.less'
import { find, get, isEmpty, noop } from 'lodash'
import { Component } from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<IContext, 'email' | 'error' | 'tenantId' | 'tenants'>
    matches(stateValue: StateValue): boolean
    startEmail(e: IEventStartEmail): void
    submitResetPasswordEmail(e: IEventSubmitResetPasswordEmail): void
}
interface IState {
    email: string
}

export class ResetPassword extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            email: props.context.email || '',
        }
    }

    onChangeEmail = (email: string) => {
        this.setState({ email })
    }

    onStartEmail = () => {
        this.props.startEmail({ type: 'startEmail' })
    }

    onSubmit = () => {
        this.props.submitResetPasswordEmail({
            type: 'submitResetPasswordEmail',
            payload: {
                email: this.state.email,
            },
        })
    }

    render() {
        const { context, matches } = this.props
        const disabled = matches({ resetPassword: 'submitting' })
        if (matches({ resetPassword: 'success' })) {
            return (
                <>
                    <Typography align="center" type="header1">
                        {i18next.t('PERC_App_Toast_Title_Success', {
                            defaultValue: enUS['PERC_App_Toast_Title_Success'],
                        })}
                    </Typography>
                    <Typography align="center" type="paragraph">
                        {i18next.t('PERC_Pident_Reset_Password', {
                            defaultValue: enUS['PERC_Pident_Reset_Password'],
                        })}
                    </Typography>
                    <Typography align="center" type="paragraph">
                        <Anchor href="mailto:support@seismic.com">
                            {i18next.t('PERC_Pident_Reset_Password_Email', {
                                defaultValue: enUS['PERC_Pident_Reset_Password_Email'],
                            })}
                        </Anchor>
                    </Typography>
                    <Button
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        label={i18next.t('PERC_Pident_Return_To_Sign_In', {
                            defaultValue: enUS['PERC_Pident_Return_To_Sign_In'],
                        })}
                        onClick={this.onStartEmail}
                        variant="primary"
                    />
                </>
            )
        }
        const tenantName = get(find(context.tenants, { id: context.tenantId }), 'name')
        return (
            <>
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Pident_Forgot_Password', {
                        defaultValue: enUS['PERC_Pident_Forgot_Password'],
                    })}
                </Typography>
                <Typography align="center" type="paragraph">
                    {i18next.t('PERC_Pident_Forgot_Password_Description', {
                        defaultValue: enUS['PERC_Pident_Forgot_Password_Description'],
                    })}
                </Typography>
                {context.error && <Error error={context.error} />}
                <Form onSubmit={this.onSubmit}>
                    {!isEmpty(tenantName) && (
                        <InputField
                            disabled
                            label={i18next.t('PERC_Organization', {
                                defaultValue: enUS['PERC_Organization'],
                            })}
                            onChange={noop}
                            type="text"
                            value={tenantName!}
                        />
                    )}
                    <InputField
                        autoFocus
                        disabled={disabled}
                        label={i18next.t('PERC_Email_Address', { defaultValue: enUS['PERC_Email_Address'] })}
                        onChange={this.onChangeEmail}
                        placeholder="name@company.com"
                        type="email"
                        value={this.state.email}
                    />
                    <Button
                        type="submit"
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        disabled={disabled}
                        label={i18next.t('PERC_Settings_Reset_Password', {
                            defaultValue: enUS['PERC_Settings_Reset_Password'],
                        })}
                        variant="primary"
                    />
                </Form>
            </>
        )
    }
}
