import classNames from 'classnames'
import styles from 'lib/components/typography.less'
import * as React from 'react'

export interface IProps {
    align?: 'left' | 'center' | 'right'
    className?: string
    children: React.ReactNode
    type: 'header1' | 'listItem' | 'paragraph' | 'unorderedList'
}

export function Typography({ align, className, children, type }: IProps) {
    switch (align) {
        case 'center':
            className = classNames(styles.alignCenter, className)
            break
        case 'left':
            className = classNames(styles.alignLeft, className)
            break
        case 'right':
            className = classNames(styles.alignRight, className)
            break
    }
    switch (type) {
        case 'header1':
            return <div className={classNames(styles.header1, className)}>{children}</div>
        case 'listItem':
            return <div className={classNames(styles.listItem, className)}>{children}</div>
        case 'paragraph':
            return <div className={classNames(styles.paragraph, className)}>{children}</div>
        case 'unorderedList':
            return <div className={classNames(styles.unorderedList, className)}>{children}</div>
    }
}
