import styles from 'apps/core/less/tooltip_content.less'
import classnames from 'classnames'
import * as _ from 'lodash-es'
import PropTypes from 'prop-types'
import { Component } from 'react'

const PADDING = {
    small: '5px',
    large: '15px',
}

export default class CoreTooltipContent extends Component {
    static propTypes = {
        children: PropTypes.node,
        maxWidth: PropTypes.number,
        width: PropTypes.number,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        className: PropTypes.string,
        padding: PropTypes.oneOf(['small', 'large']),
        inverse: PropTypes.bool,
    }

    render() {
        return (
            <div
                style={{
                    maxWidth: this.props.width || this.props.maxWidth,
                    width: this.props.width,
                }}
                className={classnames(styles.content, this.props.className, {
                    [styles.inverse]: this.props.inverse,
                })}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                <div style={{ padding: PADDING[this.props.padding] }}>
                    {_.isString(this.props.children) ? (
                        <span className={classnames('word-wrap-break-word', styles.text)}>
                            {this.props.children}
                        </span>
                    ) : (
                        this.props.children
                    )}
                </div>
            </div>
        )
    }
}
