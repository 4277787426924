import { SAML2_REQUEST_URL } from 'lib/constants'
import * as normalize from 'lib/normalize'
import { IContext } from 'lib/typing'
import { isEmpty, reduce } from 'lodash'
import { Component } from 'react'

export type IProps = Pick<
    IContext,
    'clientId' | 'email' | 'overrideSuccessRedirectUrl' | 'state' | 'tenantId' | 'idpAppId'
>

export class RedirectToIdp extends Component<IProps> {
    UNSAFE_componentWillMount() {
        const form = document.createElement('form')
        form.setAttribute('action', SAML2_REQUEST_URL)
        form.setAttribute('autoComplete', 'off')
        form.setAttribute('method', 'POST')
        form.innerHTML = reduce(
            normalize.toServer(this.props),
            (acc, v, k) => (!isEmpty(v) ? [...acc, `<input name="${k}" type="hidden" value="${v}" />`] : acc),
            []
        ).join('')
        document.body.appendChild(form)
        form.submit()
    }

    render() {
        return <div />
    }
}
