import * as i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: 'https://smc-prod-cdn.seismic.com/static/seismic-i18n/Percolate/Default/{{lng}}.json',
        },
        fallbackLng: 'en-US',
        load: 'currentOnly',
        react: {
            useSuspense: true,
        },
    })
export default i18n
