import css from 'apps/core/components/small_tooltip_content.less'
import { Component } from 'react'

interface IProps {
    text: string
}

export default class SmallTooltipContent extends Component<IProps> {
    render() {
        return <div className={css.text}>{this.props.text}</div>
    }
}
