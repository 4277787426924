/* eslint react/no-string-refs: "warn" ,  import/no-anonymous-default-export:  "warn" , @typescript-eslint/class-name-casing: "warn" */
import * as _ from 'lodash-es'
import PropTypes from 'prop-types'
import * as React from 'react'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'

const openGroupsSubject = new Subject()
const MOUSE_MOVE_TIMEOUT = 30

export default function (Component) {
    return class coreTooltipHOC extends React.Component {
        static propTypes = {
            open: PropTypes.bool,
            forceClosed: PropTypes.bool,
            group: PropTypes.string,
            interactive: PropTypes.bool,
            interactiveDelay: PropTypes.number,
            onShow: PropTypes.func,
            onHide: PropTypes.func,
        }

        static defaultProps = {
            onHide: _.noop,
            onShow: _.noop,
            interactive: false,
            interactiveDelay: 300,
        }

        constructor(props) {
            super(props)

            this.state = {
                id: _.uniqueId('coreTooltip:'),
                open: _.isBoolean(props.open) ? props.open : false,
            }
        }

        UNSAFE_componentWillMount() {
            if (this.props.group) {
                this.subscription = openGroupsSubject
                    .pipe(
                        filter(({ group, id }) => {
                            return group === this.props.group && id !== this.state.id
                        })
                    )
                    .subscribe(() => this.close())
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.forceClosed) {
                this.setState({
                    open: false,
                })
            } else if (_.isBoolean(nextProps.open)) {
                this.setState({
                    open: nextProps.open,
                })
            }
        }

        componentWillUnmount() {
            this.clearMouseMoveDelay()
            this.clearCloseDelay()
            if (this.subscription) {
                this.subscription.unsubscribe()
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.group && this.state.open) {
                openGroupsSubject.next({
                    group: this.props.group,
                    id: this.state.id,
                })
            }
            if (prevProps.open && _.isUndefined(this.props.open)) {
                // controlled -> uncontrolled
                this.close()
            }
        }

        render() {
            const props = _.omit(
                this.props,
                'open',
                'forceClosed',
                'group',
                'interactive',
                'interactiveDelay',
                'onShow',
                'onHide'
            )
            return (
                <Component
                    ref="component"
                    {...props}
                    open={this.state.open && !this.props.forceClosed}
                    onTriggerMouseMove={this.handleTriggerMouseMove}
                    onTriggerMouseLeave={
                        // if controlled, ignore mouse leave events
                        this.props.open ? () => {} : this.handleTriggerMouseLeave
                    }
                    onTooltipMouseEnter={this.handleTooltipMouseEnter}
                    onTooltipMouseLeave={
                        // if controlled, ignore mouse leave events
                        this.props.open ? () => {} : this.handleTooltipMouseLeave
                    }
                />
            )
        }

        open = () => {
            this.clearCloseDelay()
            this.setState({
                open: true,
            })
            this.props.onShow()
        }

        close = () => {
            this.clearCloseDelay()
            this.setState({
                open: false,
            })
            this.props.onHide()
        }

        closeAfterDelay = () => {
            this.clearCloseDelay()
            this.closeDelayTimeoutId = setTimeout(() => {
                this.close()
            }, this.props.interactiveDelay)
        }

        clearCloseDelay = () => {
            if (this.closeDelayTimeoutId) {
                clearTimeout(this.closeDelayTimeoutId)
                delete this.closeDelayTimeoutId
            }
        }

        clearMouseMoveDelay = () => {
            if (this.mouseMoveTimeoutId) {
                clearTimeout(this.mouseMoveTimeoutId)
                delete this.mouseMoveTimeoutId
            }
        }

        handleTriggerMouseMove = () => {
            this.clearMouseMoveDelay()
            this.mouseMoveTimeoutId = setTimeout(() => {
                this.clearMouseMoveDelay()
                this.open()
            }, MOUSE_MOVE_TIMEOUT)
        }

        handleTriggerMouseLeave = () => {
            this.clearMouseMoveDelay()
            if (!this.props.interactive) {
                this.close()
                return
            }
            this.closeAfterDelay()
        }

        handleTooltipMouseEnter = () => {
            this.clearCloseDelay()
        }

        handleTooltipMouseLeave = () => {
            this.closeAfterDelay()
        }
    }
}
