/* eslint @typescript-eslint/no-use-before-define: "warn" */
import Bluebird from 'bluebird'
import { AuthTransportError } from 'lib/auth_transport_error'
import * as normalize from 'lib/normalize'
import { IContext, IGrant, ILegacyGrant } from 'lib/typing'
import _ from 'lodash'
import querystring from 'querystring'
import reqwest from 'reqwest'
import util from 'util'

const CONTENT_TYPE_JSON = 'application/json'
const POST = 'post'
const DELETE = 'delete'
const AUTH_URL = '/auth/v5/authorize/'
export const LEGACY_AUTH_URL = '/api/v3/auth'
const RESET_PASSWORD_URL = '/api/v5/user/password'
const SESSION = 'session'
const TOKEN = 'token'

export function createAuthorization(
    state: Pick<
        IContext,
        'appClientId' | 'consent' | 'email' | 'otp' | 'password' | 'ssoPayload' | 'tenantId' | 'session'
    >
) {
    return request({
        data: JSON.stringify({
            consent: state.consent,
            email: state.email,
            password: state.password,
            sso_payload: state.ssoPayload,
            tenant_id: state.tenantId,
            two_factor_code: state.otp,
            type: SESSION,
            ...(!_.isEmpty(state.session)
                ? {
                      ext: {
                          behavior: state.session,
                      },
                  }
                : undefined),
        }),
        headers: getHeaders(state.appClientId),
        method: POST,
        url: AUTH_URL,
    })
}

export function createGrant(state: Pick<IContext, 'appClientId' | 'clientId' | 'email'>) {
    return request<IGrant>({
        data: JSON.stringify({
            email: state.email,
            type: TOKEN,
            ext: {
                client_id: state.clientId,
            },
        }),
        headers: getHeaders(state.appClientId),
        method: POST,
        url: AUTH_URL,
    })
}

export function createLegacySsoAuthorization(state: Pick<IContext, 'ssoPayload' | 'v3AuthApiToken'>) {
    return request<ILegacyGrant>({
        data: JSON.stringify({
            sso_payload: state.ssoPayload,
        }),
        headers: {
            'X-Pident-Auth-Token': state.v3AuthApiToken,
        },
        method: POST,
        url: LEGACY_AUTH_URL,
    })
}

export function createPassword(
    state: Pick<IContext, 'appClientId' | 'password' | 'tenantId' | 'token' | 'userId'>
) {
    return request({
        data: JSON.stringify({
            password: state.password,
            tenant_id: state.tenantId,
            token: state.token,
            user_id: state.userId,
        }),
        headers: getHeaders(state.appClientId),
        method: POST,
        url: '/auth/v5/password/',
    })
}

export function deletePassword(state: Pick<IContext, 'appClientId' | 'email' | 'tenantId'>) {
    return request({
        headers: getHeaders(state.appClientId),
        method: DELETE,
        url: [
            RESET_PASSWORD_URL,
            querystring.stringify({
                email: state.email,
                ...(state.tenantId
                    ? {
                          tenant_id: state.tenantId,
                      }
                    : undefined),
            }),
        ].join('?'),
    })
}

function getHeaders(appClientId?: string) {
    return {
        Authorization: util.format('Client-ID %s', appClientId),
    }
}

function request<Response>(options?: Reqwest.ReqwestOptions) {
    return new Bluebird<Response>(function (resolve, reject) {
        reqwest(
            _.extend(
                {},
                {
                    contentType: CONTENT_TYPE_JSON,
                    error: function (xhr: XMLHttpRequest) {
                        return reject(new AuthTransportError(tryParseJSON(xhr.responseText)))
                    },
                    success: function (res: any) {
                        return resolve(normalize.fromServer(res))
                    },
                },
                options
            )
        )
    })
}

function tryParseJSON(obj: string) {
    try {
        obj = JSON.parse(obj)
    } catch (ex) {
        return undefined
    }
    return obj
}
