const crossBrowserRequestAnimationFrame = (function () {
    const raf =
        window.requestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        function (callback) {
            return setTimeout(callback, 1000 / 60)
        }

    return function (callback) {
        return raf.call(window, callback)
    }
})()

export const requestAnimationFrame = crossBrowserRequestAnimationFrame
