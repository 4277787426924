import { Button } from '@percolate/ui'
import classNames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { RedirectToUrl } from 'lib/components/redirect_to_url'
import { Typography } from 'lib/components/typography'
import { IContext } from 'lib/typing'
import utilStyles from 'lib/util.less'

export interface IProps {
    context: Pick<IContext, 'overrideSuccessRedirectUrl'>
}

export function RedirectingToUrl({ context }: IProps) {
    return (
        <>
            <Typography align="center" type="header1">
                {i18next.t('PERC_Redirecting', { defaultValue: enUS['PERC_Redirecting'] })}
            </Typography>
            <Typography align="center" type="paragraph">
                {i18next.t('PERC_Pident_Redirect', { defaultValue: enUS['PERC_Pident_Redirect'] })}
            </Typography>
            <a href={context.overrideSuccessRedirectUrl}>
                <Button
                    className={classNames(
                        utilStyles.submitButton,
                        utilStyles.pushTopXxl,
                        utilStyles.formButton
                    )}
                    label={i18next.t('PERC_Continue', { defaultValue: enUS['PERC_Continue'] })}
                    variant="primary"
                />
            </a>
            <RedirectToUrl url={context.overrideSuccessRedirectUrl!} />
        </>
    )
}
