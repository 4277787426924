import Bluebird from 'bluebird'
import { ERRORS } from 'lib/constants'
import { IAnyObject, ITenant } from 'lib/typing'
import _ from 'lodash'

interface IBaseError {
    code: ERRORS
}

interface ITenantRequiredError extends IBaseError {
    code: ERRORS.tenantIdRequired
    tenants: ITenant[]
}

interface IOtpBlockError extends IBaseError {
    code: ERRORS.otpBlock
    otpSecretSetupToken: string
}

interface IConsentRequiredError extends IBaseError {
    code: ERRORS.consentRequired
    consentTerms: string | undefined
}

interface IDefaultError {
    loginAttempts?: number
    code:
        | ERRORS.autoProvisionSso
        | ERRORS.badRequest
        | ERRORS.emptyEmail
        | ERRORS.invalidClient
        | ERRORS.invalidCredentials
        | ERRORS.invalidOtp
        | ERRORS.invalidSubdomain
        | ERRORS.invalidToken
        | ERRORS.isSsoUser
        | ERRORS.otpRequired
        | ERRORS.passwordForbiddenContent
        | ERRORS.passwordLoginLocked
        | ERRORS.passwordMissingLowercase
        | ERRORS.passwordMissingNumber
        | ERRORS.passwordMissingSymbol
        | ERRORS.passwordMissingUppercase
        | ERRORS.passwordReused
        | ERRORS.passwordTooShort
        | ERRORS.rateLimitExceeded
        | ERRORS.ssoGeneralError
        | ERRORS.ssoInvalidSignature
        | ERRORS.ssoMissingEmailAttribute
        | ERRORS.tenantResetPasswordRequired
        | ERRORS.unknown
}

type AuthError = ITenantRequiredError | IOtpBlockError | IConsentRequiredError | IDefaultError

export class AuthTransportError extends Bluebird.OperationalError {
    data: AuthError

    constructor(json?: any) {
        super('AuthError')
        const data: IAnyObject = _.get(json, ['errors', 0], {})
        const code: ERRORS = _.values(ERRORS).some(c => c === data.code) ? data.code : ERRORS.unknown
        const { login_attempts: loginAttempts } = data

        switch (code) {
            case ERRORS.otpBlock:
                this.data = {
                    code,
                    otpSecretSetupToken: data.otp_secret_setup_token,
                }
                break
            case ERRORS.consentRequired:
                this.data = {
                    code,
                    consentTerms: data.consent_terms ? data.consent_terms : undefined,
                }
                break
            case ERRORS.tenantIdRequired:
                if (_.size(data.tenants) === 0) {
                    throw new Error('tenants required')
                }
                this.data = {
                    code,
                    tenants: data.tenants,
                }
                break
            case ERRORS.invalidCredentials:
                this.data = { code, loginAttempts }
                break
            default:
                this.data = { code }
                break
        }
    }
}
