import { Button } from '@percolate/ui'
import classNames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { Error } from 'lib/components/error'
import { Form } from 'lib/components/form'
import { InputField } from 'lib/components/input_field'
import { RedirectToUrl } from 'lib/components/redirect_to_url'
import { Typography } from 'lib/components/typography'
import { IContext, IEventSubmitTwoFactorAuthCode } from 'lib/typing'
import utilStyles from 'lib/util.less'
import { Component } from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<IContext, 'error' | 'overrideSuccessRedirectUrl'>
    matches(stateValue: StateValue): boolean
    submitTwoFactorAuthCode(e: IEventSubmitTwoFactorAuthCode): void
}
interface IState {
    otp: string
}

export class TwoFactorAuthCode extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            otp: '',
        }
    }

    onChangeOtp = (otp: string) => {
        this.setState({ otp })
    }

    onSubmit = () => {
        this.props.submitTwoFactorAuthCode({
            type: 'submitTwoFactorAuthCode',
            payload: {
                otp: this.state.otp,
            },
        })
    }

    render() {
        const { context, matches } = this.props
        const disabled =
            matches({ twoFactorAuthCode: 'submitting' }) || matches({ twoFactorAuthCode: 'redirectingToApp' })
        const canSubmit = this.state.otp.length >= 6
        return (
            <>
                {matches({ twoFactorAuthCode: 'redirectingToApp' }) && (
                    <RedirectToUrl url={context.overrideSuccessRedirectUrl!} />
                )}
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Pident_Two_Factor_Auth', {
                        defaultValue: enUS['PERC_Pident_Two_Factor_Auth'],
                    })}
                </Typography>
                <Typography align="center" type="paragraph">
                    {i18next.t('PERC_Pident_Two_Factor_Auth_Code', {
                        defaultValue: enUS['PERC_Pident_Two_Factor_Auth_Code'],
                    })}
                </Typography>
                {context.error && <Error error={context.error} />}
                <Form onSubmit={this.onSubmit}>
                    <InputField
                        autoFocus
                        disabled={disabled}
                        label={i18next.t('PERC_Settings_Auth_Code', {
                            defaultValue: enUS['PERC_Settings_Auth_Code'],
                        })}
                        onChange={this.onChangeOtp}
                        placeholder="XXX-XXX"
                        preserveFocusOnUpdate
                        type="otp"
                        value={this.state.otp}
                    />
                    <Button
                        type="submit"
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        disabled={disabled || !canSubmit}
                        label={i18next.t('PERC_Submit', { defaultValue: enUS['PERC_Submit'] })}
                        variant="primary"
                    />
                </Form>
            </>
        )
    }
}
