import css from 'apps/core/less/popper.less'
import Resizer from 'apps/dom_utils/resizer'
import classnames from 'classnames'
import * as _ from 'lodash-es'
import PropTypes from 'prop-types'
import { Children, Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Manager, Popper as ReactPoppper, Reference } from 'react-popper'
import { VelocityTransitionGroup } from 'velocity-react'

export const slideTransition = {
    runOnMount: true,
    enter: {
        animation: {
            scaleY: [1, 0.8],
            opacity: 1,
        },
        duration: 175,
        easing: 'ease-out',
    },
    leave: {
        animation: {
            opacity: 0,
        },
        duration: 100,
        easing: 'ease-out',
    },
}

export const fadeTransition = {
    runOnMount: true,
    enter: {
        animation: {
            opacity: 1,
        },
        duration: 175,
        easing: 'ease-in-out',
    },
    leave: {
        animation: {
            opacity: 0,
        },
        duration: 100,
        easing: 'ease-in-out',
    },
}

export default class Popper extends Component {
    static propTypes = {
        children: PropTypes.array,
        placement: PropTypes.string,
        boundariesElement: PropTypes.oneOf(['window', 'scrollParent', 'viewport']),
        offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        className: PropTypes.string,
        showing: PropTypes.bool,
        inline: PropTypes.bool,
        transitionGroupOptions: PropTypes.shape({
            ref: PropTypes.func,
            enter: PropTypes.shape({
                begin: PropTypes.func,
            }),
            leave: PropTypes.shape({
                complete: PropTypes.func,
            }),
        }),
        arrow: PropTypes.bool,
        arrowClassName: PropTypes.string,
        unrestrictedHeight: PropTypes.bool,
    }

    static defaultProps = {
        placement: 'bottom-start',
        boundariesElement: 'viewport',
        inline: false,
        className: '',
        offset: 0,
        showing: true,
        transitionGroupOptions: fadeTransition,
    }

    static mockShowing(props = {}) {
        return props.showing
    }

    constructor(props) {
        super(props)
        this.state = {
            leaving: false,
            showing: Popper.mockShowing(props),
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.showing !== nextProps.showing) {
            const leaving = !nextProps.showing && this.state.showing
            this.setState({ showing: Popper.mockShowing(nextProps), leaving })
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true
    }

    render() {
        const children = Children.toArray(this.props.children)
        if (!children.length) return null

        const popper = this.state.showing || this.state.leaving ? this.renderPopperWrapper() : null

        if (TEST) {
            return (
                <Fragment>
                    {children[0]}
                    {popper}
                </Fragment>
            )
        }

        return (
            <Manager>
                <Reference>
                    {({ ref }) => {
                        this.popperRef = ref
                        return <Refable ref={this.handleRefableRef}>{children[0]}</Refable>
                    }}
                </Reference>
                {popper}
            </Manager>
        )
    }

    renderPopperWrapper() {
        if (TEST) {
            return this.renderPopper()
        }
        const popper = (
            <ReactPoppper
                modifiers={{
                    setMaxHeight: { enabled: true, order: 0, fn: this.maxHeightModifier },
                    offset: { offset: this.props.offset },
                    preventOverflow: { boundariesElement: this.props.boundariesElement },
                }}
                placement={this.props.placement}
            >
                {this.renderPopper}
            </ReactPoppper>
        )

        return this.props.inline ? (
            <div key="inlinePopper">{popper}</div>
        ) : (
            ReactDOM.createPortal(popper, document.body)
        )
    }

    renderPopper = ({ ref, style, placement, scheduleUpdate = _.noop, arrowProps = {} } = {}) => {
        const {
            className,
            children,
            transitionGroupOptions: { leave = {}, ...transitionGroupOptions } = {},
        } = this.props

        return (
            <div
                key="popper"
                ref={ref}
                style={style}
                className={classnames(className, 'popperElement', {
                    [css.withArrow]: this.props.arrow,
                    [css.placementTop]: placement === 'top',
                    [css.placementBottom]: placement === 'bottom',
                    [css.placementLeft]: placement === 'left',
                    [css.placementRight]: placement === 'right',
                })}
            >
                <VelocityTransitionGroup
                    component={null}
                    {...transitionGroupOptions}
                    leave={{
                        ...leave,
                        complete: this.handleLeaveComplete,
                    }}
                >
                    {this.state.showing && (
                        <div
                            style={{
                                transformOrigin: (placement || '').startsWith('bottom') ? 'top' : 'bottom',
                            }}
                        >
                            {this.props.arrow ? (
                                <span
                                    className={classnames(css.arrow, this.props.arrowClassName, {
                                        [css.arrowTop]: placement === 'top',
                                        [css.arrowBottom]: placement === 'bottom',
                                        [css.arrowLeft]: placement === 'left',
                                        [css.arrowRight]: placement === 'right',
                                    })}
                                    {...arrowProps}
                                />
                            ) : null}
                            <Resizer onResize={scheduleUpdate}>{children[1]}</Resizer>
                        </div>
                    )}
                </VelocityTransitionGroup>
            </div>
        )
    }

    handleRefableRef = node => {
        // proxy the DOM node of children[0] ref to popper
        let refNode = node
        if (node) {
            // eslint-disable-next-line react/no-find-dom-node
            refNode = ReactDOM.findDOMNode(node)
        }
        if (this.popperRef) {
            this.popperRef(refNode)
        }
    }

    maxHeightModifier = data => {
        // the DOM node of children[1]
        const popperChild = _.get(data, ['instance', 'popper', 'firstElementChild'])
        if (popperChild) {
            // preventOverflow modifer has a default padding of 5px, this adds a little extra to that
            const extraSpace = 15
            // limit height to 500px if applicable
            const minAvailableHeight = this.props.unrestrictedHeight ? Infinity : 500

            // calculate the available space within the window above and below the reference element
            const referenceRect = data.instance.reference.getBoundingClientRect()
            const availableTopSpace = Math.min(minAvailableHeight, Math.ceil(referenceRect.top) - extraSpace)
            const availableBottomSpace = Math.min(
                minAvailableHeight,
                window.innerHeight - Math.ceil(referenceRect.bottom) - extraSpace
            )

            // reset popperChild style for measuring the true height
            popperChild.style.maxHeight = ''
            popperChild.style.overflowY = ''
            const popperHeight = data.instance.popper.clientHeight

            if (availableTopSpace < popperHeight && availableBottomSpace < popperHeight) {
                // if the popper is taller than the available space, use whichever is taller
                let maxHeight = Math.max(availableTopSpace, availableBottomSpace)
                if (maxHeight < 200) {
                    // in small windows, allow popper to take up the window height
                    // causes the popper to appear on top of the reference
                    maxHeight = window.innerHeight - extraSpace
                }

                // set popperChild maxHeight and overflow
                popperChild.style.maxHeight = `${maxHeight}px`
                popperChild.style.overflowY = 'auto'

                // manually update the cached popper height to what we just calculated
                data.offsets.popper.height = maxHeight
            } else {
                // else allow popper to choose whether to flip the position
                data.offsets.popper.height = popperHeight
            }
        }

        return data
    }

    handleLeaveComplete = (...args) => {
        if (!this.isUnmounted) {
            this.setState({ leaving: false, showing: false }, () => {
                _.get(this, 'props.transitionGroupOptions.leave.complete', _.noop)(...args)
            })
        }
    }
}

// eslint-disable-next-line react/no-multi-comp
class Refable extends Component {
    static propTypes = {
        children: PropTypes.node,
    }
    render() {
        return this.props.children
    }
}
