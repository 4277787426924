import { percolateTheme, Theme } from '@percolate/ui'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import styles from 'lib/app.less'
import { CreatePassword } from 'lib/create_password'
import { Email } from 'lib/email'
import { GdprConsent } from 'lib/gdpr_consent'
import { Grant } from 'lib/grant'
import { InitializingSso } from 'lib/initializing_sso'
import { Locked } from 'lib/locked'
import { machine } from 'lib/machine'
import { Origin } from 'lib/origin'
import { Password } from 'lib/password'
import { RedirectingToSaml2 } from 'lib/redirecting_to_saml2'
import { RedirectingToUrl } from 'lib/redirecting_to_url'
import { ResetPassword } from 'lib/reset_password'
import { Tenant } from 'lib/tenant'
import { TwoFactorAuthCode } from 'lib/two_factor_auth_code'
import { IContext, IEvent, IEventBootstrap } from 'lib/typing'
import { Component } from 'react'
import Logo from 'svg/logo-full-color.svg'
import { interpret, State } from 'xstate'

const LOGO_WIDTH = 248
const LOGO_HEIGHT = 100

export interface IProps {
    bootstrap: IEventBootstrap['payload']
}
interface IState {
    current?: State<IContext, IEvent>
}

export class App extends Component<IProps, IState> {
    public service = interpret(machine).onTransition(current => this.setState({ current }))

    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.service.start()
        this.service.send({
            type: 'bootstrap',
            payload: this.props.bootstrap,
        })
        this.onSend({ type: 'start' })
    }

    componentWillUnmount() {
        this.service.stop()
    }

    onSend = (e: IEvent) => {
        this.service.send(e)
    }

    render() {
        if (!this.state.current) {
            return null
        }
        const { current } = this.state
        const { context } = current
        const matches = current.matches.bind(current)
        return (
            <Theme theme={percolateTheme({ useGlobal: false })}>
                <div className={styles.root}>
                    <div className={styles.dialog}>
                        <div className={styles.content}>
                            <div className={styles.logo}>
                                <Logo style={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />
                            </div>
                            {(() => {
                                if (current.matches('createPassword')) {
                                    return (
                                        <CreatePassword
                                            context={{
                                                error: context.error,
                                                tenantId: context.tenantId,
                                                tenants: context.tenants,
                                            }}
                                            matches={matches}
                                            startResetPassword={this.onSend}
                                            startEmail={this.onSend}
                                            submitPassword={this.onSend}
                                        />
                                    )
                                } else if (current.matches('email')) {
                                    return (
                                        <Email
                                            context={{
                                                clientId: context.clientId,
                                                email: context.email,
                                                error: context.error,
                                                overrideSuccessRedirectUrl:
                                                    context.overrideSuccessRedirectUrl,
                                                state: context.state,
                                                idpAppId: context.idpAppId,
                                            }}
                                            matches={matches}
                                            startOrigin={this.onSend}
                                            submitEmail={this.onSend}
                                        />
                                    )
                                } else if (current.matches('gdprConsent')) {
                                    return (
                                        <GdprConsent
                                            context={{
                                                consentTerms: context.consentTerms,
                                                error: context.error,
                                                overrideSuccessRedirectUrl:
                                                    context.overrideSuccessRedirectUrl,
                                            }}
                                            matches={matches}
                                            submitGdprConsent={this.onSend}
                                        />
                                    )
                                } else if (current.matches('grant')) {
                                    return (
                                        <Grant
                                            context={{
                                                capabilities: context.capabilities,
                                                clientName: context.clientName,
                                                error: context.error,
                                                grant: context.grant,
                                                state: context.state,
                                            }}
                                            matches={matches}
                                            submitGrant={this.onSend}
                                        />
                                    )
                                } else if (current.matches('initializingSso')) {
                                    return (
                                        <InitializingSso
                                            context={{
                                                error: context.error,
                                                overrideSuccessRedirectUrl:
                                                    context.overrideSuccessRedirectUrl,
                                            }}
                                            matches={matches}
                                        />
                                    )
                                } else if (current.matches('locked')) {
                                    return (
                                        <Locked
                                            context={{
                                                email: context.email,
                                                error: context.error,
                                                tenantId: context.tenantId,
                                                tenants: context.tenants!,
                                            }}
                                            matches={matches}
                                            startEmail={this.onSend}
                                            submitResetPasswordEmail={this.onSend}
                                        />
                                    )
                                } else if (current.matches('origin')) {
                                    return (
                                        <Origin
                                            context={{
                                                error: context.error,
                                                origin: context.origin,
                                            }}
                                            matches={matches}
                                            startEmail={this.onSend}
                                            submitOrigin={this.onSend}
                                        />
                                    )
                                } else if (current.matches('password')) {
                                    return (
                                        <Password
                                            context={{
                                                error: context.error,
                                                overrideSuccessRedirectUrl:
                                                    context.overrideSuccessRedirectUrl,
                                                password: context.password,
                                            }}
                                            startEmail={this.onSend}
                                            startResetPassword={this.onSend}
                                            matches={matches}
                                            submitPassword={this.onSend}
                                        />
                                    )
                                } else if (current.matches('redirectingToApp')) {
                                    return (
                                        <RedirectingToUrl
                                            context={{
                                                overrideSuccessRedirectUrl:
                                                    context.overrideSuccessRedirectUrl,
                                            }}
                                        />
                                    )
                                } else if (current.matches('redirectingSaml2')) {
                                    return (
                                        <RedirectingToSaml2
                                            context={{
                                                saml2IdpUri: context.saml2IdpUri,
                                                saml2Request: context.saml2Request,
                                                saml2Token: context.saml2Token,
                                            }}
                                        />
                                    )
                                } else if (current.matches('resetPassword')) {
                                    return (
                                        <ResetPassword
                                            context={{
                                                email: context.email,
                                                error: context.error,
                                                tenantId: context.tenantId,
                                                tenants: context.tenants!,
                                            }}
                                            matches={matches}
                                            startEmail={this.onSend}
                                            submitResetPasswordEmail={this.onSend}
                                        />
                                    )
                                } else if (current.matches('tenant')) {
                                    return (
                                        <Tenant
                                            context={{
                                                clientId: context.clientId,
                                                email: context.email,
                                                error: context.error,
                                                overrideSuccessRedirectUrl:
                                                    context.overrideSuccessRedirectUrl,
                                                password: context.password,
                                                state: context.state,
                                                tenantId: context.tenantId!,
                                                tenants: context.tenants!,
                                            }}
                                            complete={this.onSend}
                                            matches={matches}
                                            startEmail={this.onSend}
                                            startResetPassword={this.onSend}
                                            submitPassword={this.onSend}
                                            submitTenantId={this.onSend}
                                        />
                                    )
                                } else if (current.matches('twoFactorAuthCode')) {
                                    return (
                                        <TwoFactorAuthCode
                                            context={{
                                                error: context.error,
                                                overrideSuccessRedirectUrl:
                                                    context.overrideSuccessRedirectUrl,
                                            }}
                                            matches={matches}
                                            submitTwoFactorAuthCode={this.onSend}
                                        />
                                    )
                                }
                                return null
                            })()}
                        </div>
                        <div className={styles.footer}>
                            <a href="https://percolate.com/privacy">
                                {i18next.t('PERC_Settings_Privacy_Policy', {
                                    percolate: 'Percolate',
                                    defaultValue: enUS['PERC_Settings_Privacy_Policy'],
                                })}
                            </a>
                            <a href="mailto:support@seismic.com">
                                {i18next.t('PERC_Contact_Us', { defaultValue: enUS['PERC_Contact_Us'] })}
                            </a>
                        </div>
                    </div>
                </div>
            </Theme>
        )
    }
}
