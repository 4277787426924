import { IContext } from 'lib/typing'
import { Component } from 'react'

export type IProps = Required<Pick<IContext, 'saml2IdpUri' | 'saml2Request' | 'saml2Token'>>

export class RedirectToSaml2 extends Component<IProps> {
    UNSAFE_componentWillMount() {
        const { saml2IdpUri, saml2Request, saml2Token } = this.props
        const form = document.createElement('form')
        form.setAttribute('action', saml2IdpUri)
        form.setAttribute('autoComplete', 'off')
        form.setAttribute('method', 'POST')
        form.innerHTML = [
            `<input name="SAMLRequest" type="hidden" value="${saml2Request}" />`,
            `<input name="RelayState" type="hidden" value="${saml2Token}" />`,
        ].join('')
        document.body.appendChild(form)
        form.submit()
    }

    render() {
        return <div />
    }
}
