/* eslint lodash/chaining: "warn" */
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { Error } from 'lib/components/error'
import { LoadingIcon } from 'lib/components/loading_icon'
import { Typography } from 'lib/components/typography'
import { IContext } from 'lib/typing'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<IContext, 'error' | 'overrideSuccessRedirectUrl'>
    matches(stateValue: StateValue): boolean
}

export function InitializingSso({ context, matches }: IProps) {
    if (matches({ initializingSso: 'error' })) {
        return (
            <>
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Settings_Session_Error', {
                        defaultValue: enUS['PERC_Settings_Session_Error'],
                    })}
                </Typography>
                {context.error && <Error error={context.error} />}
            </>
        )
    } else {
        return (
            <>
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Loading', { defaultValue: enUS['PERC_Loading'] })}
                </Typography>
                <div>
                    <LoadingIcon />
                </div>
            </>
        )
    }
}
