import { location } from 'common/location'
import { Component } from 'react'

export interface IProps {
    url: string
}

export class RedirectToUrl extends Component<IProps> {
    UNSAFE_componentWillMount() {
        location.assign(this.props.url)
    }

    render() {
        return <div />
    }
}
