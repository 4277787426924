import Dropdown from 'apps/core/dropdown'
import { Field, IProps as IFieldProps } from 'lib/components/field'
import styles from 'lib/components/select_field.less'
import { find, get } from 'lodash'
import { Component } from 'react'

export interface IProps extends Pick<IFieldProps, 'label'> {
    autoFocus?: boolean
    disabled?: boolean
    onChange(value: string): void
    options: {
        label: string | number
        value: string | number
    }[]
    value: string | number
}

export class SelectField extends Component<IProps> {
    render() {
        const { disabled, label, onChange, options, value } = this.props
        return (
            <Field label={label}>
                <Dropdown
                    className={styles.dropdown}
                    closeOnItemClick
                    disabled={disabled}
                    label={get(find(options, { value }), 'label')}
                    onClick={onChange}
                    options={options}
                />
            </Field>
        )
    }
}
