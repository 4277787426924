import { Icon, IconVerticalDots } from '@percolate/ui'
import { KEYS } from 'apps/constants/keys'
import styles from 'apps/core/less/dropdown.less'
import Popper, { slideTransition } from 'apps/core/popper'
import Tooltip from 'apps/core/tooltip'
import classnames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import * as _ from 'lodash-es'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'

const HOVER_INTERVAL = 30

export default class Dropdown extends Component {
    static propTypes = {
        boundariesElement: PropTypes.string,
        className: PropTypes.string,
        closeOnItemClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
        noLabelArrow: PropTypes.bool,
        options: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.any,
                    searchValue: PropTypes.string,
                    exclude: PropTypes.bool,
                    disabled: PropTypes.bool,
                }),
                PropTypes.shape({
                    element: PropTypes.node.isRequired,
                    value: PropTypes.any,
                    searchValue: PropTypes.string,
                    exclude: PropTypes.bool,
                    disabled: PropTypes.bool,
                }),
                PropTypes.shape({
                    heading: PropTypes.string.isRequired,
                }),
                PropTypes.shape({
                    divider: PropTypes.string.isRequired,
                }),
            ])
        ),
        open: PropTypes.bool,
        triggerSize: PropTypes.oneOf([18, 24, 32, 44]),
        triggerIconSize: PropTypes.oneOf([16, 20, 24]),
        inline: PropTypes.bool,
        inNavbar: PropTypes.bool,
        position: PropTypes.oneOf(['left', 'right']),
        onClick: PropTypes.func,
        onOpenChanged: PropTypes.func,
        searchable: PropTypes.bool,
        minWidth: PropTypes.number,
        maxHeight: PropTypes.number,
        noBorders: PropTypes.bool,
        noBackground: PropTypes.bool,
        primaryButton: PropTypes.bool,
        solidArrowLabel: PropTypes.bool,
        hoverable: PropTypes.bool,
        hideOutsideBoundary: PropTypes.bool,
        disabled: PropTypes.bool,
        hasError: PropTypes.bool,
    }

    static defaultProps = {
        closeOnItemClick: true,
        triggerSize: 32,
        triggerIconSize: 24,
        onClick: _.noop,
        options: [],
        position: 'right',
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps !== prevState.props) {
            return {
                open: _.isBoolean(nextProps.open) ? nextProps.open : prevState.open,
                options: nextProps.options,
                props: nextProps,
            }
        }
        return null
    }

    menu = createRef()
    activeItem = createRef()

    _getInitialState(props) {
        return {
            open: _.isBoolean(props.open) ? props.open : false,
            options: props.options,
            minWidth: null,
            activeIndex: null,
            search: '',
        }
    }

    constructor(props) {
        super(props)
        this.state = this._getInitialState(props)
    }

    componentDidMount = () => {
        this.manageEvents()
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.manageEvents()

        if (this.props.onOpenChanged && prevState.open !== this.state.open) {
            this.props.onOpenChanged(this.state.open)
        }

        if (this.state.open) {
            this.updateMinWidth()
        }

        // Focus on search if we're opening the dropdown and it's searchable
        if (this.props.searchable && !prevState.open && this.state.open && this.search) {
            _.defer(() => this.search && this.search.focus())
        }
    }

    componentWillUnmount = () => {
        this.unbindEvents()
        clearInterval(this._hoverInterval)
    }

    render = () => {
        return (
            <Popper
                inline={this.props.inline}
                showing={this.state.open}
                placement={this.props.position === 'left' ? 'bottom-end' : 'bottom-start'}
                transitionGroupOptions={slideTransition}
                boundariesElement={this.props.boundariesElement}
                className={this.props.hideOutsideBoundary && 'hideOutsideBoundary'}
                unrestrictedHeight={this.props.inNavbar}
            >
                {this.renderTrigger()}
                {this.renderMenu()}
            </Popper>
        )
    }

    renderTrigger() {
        const triggerNoBorders =
            (!this.props.label && this.props.noBorders !== false) || this.props.noBorders === true
        const triggerNoBackground =
            (!this.props.label && this.props.noBackground !== false) || triggerNoBorders

        const className = classnames(styles.trigger, this.props.className, {
            [styles.triggerNoBorders]: triggerNoBorders,
            [styles.triggerNoBackground]: triggerNoBackground,
            [styles.triggerNoLabel]: !this.props.label,
            [styles.inNavbar]: this.props.inNavbar,
            [styles.triggerOpen]: this.state.open,
            [styles.triggerDisabled]: this.props.disabled,
            [styles.triggerError]: !!this.props.hasError,
            [styles.triggerSize44]: this.props.triggerSize === 44,
            [styles.triggerSize32]: this.props.triggerSize === 32,
            [styles.triggerSize24]: this.props.triggerSize === 24,
            [styles.triggerSize18]: this.props.triggerSize === 18,
            'form-control': true,
            'fn-trigger': true,
        })

        const trigger = (
            <div
                className={className}
                onClick={this.handleTriggerClick}
                ref={node => (this.trigger = node)}
                onMouseLeave={this.onMouseLeave}
                onMouseMove={this.onMouseMove}
                onMouseEnter={this.onMouseEnter}
            >
                {this.renderLabel()}
            </div>
        )
        if (this.props.label) {
            return trigger
        }
        return (
            <Tooltip
                forceClosed={false}
                anchorBottom
                small
                text={i18next.t('PERC_More_Actions', { defaultValue: enUS['PERC_More_Actions'] })}
                display="inline-flex"
            >
                {trigger}
            </Tooltip>
        )
    }

    renderLabel = () => {
        if (_.isFunction(this.props.label)) return this.props.label(this.state)
        if (!this.props.label) return <IconVerticalDots size={this.props.triggerIconSize} />
        if (this.props.noLabelArrow) return <span>{this.props.label}</span>

        let iconName
        let iconSize

        if (this.props.solidArrowLabel) {
            iconName = 'icon-arrow-down'
            iconSize = 10
        } else {
            iconName = 'icon-down'
            iconSize = 16
        }

        return (
            <div className={styles.triggerLabelWithArrow}>
                <div className="text-overflow">{this.props.label}</div>
                <div>
                    <Icon name={iconName} size={iconSize} className="push-left" />
                </div>
            </div>
        )
    }

    renderMenu() {
        const className = classnames(styles.menu, styles.tetherDropdown, {
            [styles.inNavbar]: this.props.inNavbar,
            [styles.menuClosed]: !this.state.open,
        })

        return (
            <div
                className={className}
                ref={this.menu}
                style={{
                    minWidth: this.state.minWidth || this.props.minWidth || 'initial',
                    maxHeight: this.props.maxHeight,
                }}
                onMouseLeave={this.onMouseLeave}
                onMouseMove={this.onMouseMove}
            >
                {this.renderOptions()}
            </div>
        )
    }

    renderOptions() {
        const filteredOptions = this.getFilteredOptions()
        return this.state.options.map((option, index) => {
            const disabled = _.isBoolean(option.disabled) ? option.disabled : false
            const isActive = _.indexOf(filteredOptions, option) === this.state.activeIndex

            if (option.heading) {
                return (
                    <div className={styles.menuItemHeading} key={option.heading}>
                        <h3 className="flush">{option.heading}</h3>
                    </div>
                )
            }

            if (option.divider) {
                return <div className={styles.menuItemDivider} key={option.divider} />
            }

            const className = classnames(styles.menuItem, {
                [styles.menuItemDisabled]: disabled,
                [styles.menuItemActive]: isActive,
            })

            const key = _.isObject(option.value) ? option.label : option.value

            return (
                <div
                    className={className}
                    onClick={e => this.handleItemClick(option.value, disabled, e)}
                    key={!_.isNil(key) ? key : index}
                    ref={isActive ? this.activeItem : undefined}
                >
                    {option.element ? option.element : <div>{option.label}</div>}
                </div>
            )
        })
    }

    manageEvents = () => {
        if (this.state.open) {
            this.bindEvents()
        } else {
            this.unbindEvents()
        }
    }

    bindEvents = () => {
        document.addEventListener('click', this.handleDocumentClick, false)
    }

    unbindEvents = () => {
        document.removeEventListener('click', this.handleDocumentClick, false)
    }

    handleTriggerClick = e => {
        if (this.props.disabled) return
        if (!this.props.hoverable) {
            e.preventDefault()

            this.setState(state => ({
                open: !state.open,
            }))
        }
    }

    onMouseEnter = () => {
        if (this.props.hoverable) this._hovering = true
    }

    onMouseLeave = () => {
        if (this.props.hoverable) {
            clearInterval(this._hoverInterval)
            this._hovering = false
            this.setState({ open: false })
        }
    }

    onMouseMove = e => {
        const { clientX, clientY } = e
        if (this.props.hoverable && this._hovering) {
            clearInterval(this._hoverInterval)
            this._hoverInterval = setInterval(() => {
                // Open if the mouse hasn't moved within an interval
                if (clientX === this._prevClientX && clientY === this._prevClientY) {
                    clearInterval(this._hoverInterval)
                    this.setState({ open: true })
                    this._prevClientX = null
                    this._prevClientY = null
                } else {
                    this._prevClientX = clientX
                    this._prevClientY = clientY
                }
            }, HOVER_INTERVAL)
        }
    }

    handleDocumentClick = e => {
        if (this.menu.current && this.menu.current.contains(e.target)) return undefined

        this.setState({
            open: false,
            search: '',
            options: this.props.options,
            activeIndex: null,
        })
    }

    handleItemClick = (value, disabled, e) => {
        let shouldClose = this.props.closeOnItemClick
        if (_.isFunction(shouldClose)) {
            shouldClose = shouldClose(value)
        }

        if (shouldClose) {
            this.setState(this._getInitialState(this.props), () => {
                if (!disabled) {
                    this.props.onClick(value)
                }
            })

            if (e.isTrusted) {
                e.nativeEvent.stopImmediatePropagation()
            }
            return
        }
        if (!disabled) {
            this.props.onClick(value)
        }

        if (e.isTrusted) {
            e.nativeEvent.stopImmediatePropagation()
        }
    }

    handleSearch = e => {
        const value = e.target.value

        if (!value) {
            // Reset options if there's no search
            this.setState({
                options: this.props.options,
                search: '',
                activeIndex: null,
            })
            return undefined
        }

        this.setState({
            search: value,
            options: this.props.options.filter(option => {
                const searchValue = option.searchValue || option.label
                if (searchValue && !option.exclude) {
                    if (searchValue.search(new RegExp(_.escapeRegExp(value.trim()), 'gi')) > -1) {
                        return option
                    }
                } else {
                    return option
                }
            }),
            activeIndex: null,
        })
    }

    onKeyDown = e => {
        const { activeIndex } = this.state
        const filteredOptions = this.getFilteredOptions()

        switch (e.which) {
            case KEYS.ESC:
                this.setState({
                    open: false,
                    search: '',
                    options: this.props.options,
                    activeIndex: null,
                })
                break
            case KEYS.UP_ARROW:
                if (_.isNull(activeIndex) && filteredOptions.length) {
                    this.setState({ activeIndex: 0 })
                } else if (activeIndex > 0) {
                    this.setState({ activeIndex: activeIndex - 1 })
                }
                break
            case KEYS.DOWN_ARROW:
                if (_.isNull(activeIndex) && filteredOptions.length) {
                    this.setState({ activeIndex: 0 })
                } else if (this.state.activeIndex < filteredOptions.length - 1) {
                    this.setState({ activeIndex: activeIndex + 1 })
                }
                break
            case KEYS.ENTER:
                const value = _.get(filteredOptions, [activeIndex, 'value'])
                if (value) {
                    if (this.props.onClick !== _.noop) {
                        this.props.onClick(value)
                    }
                }
                break
        }
    }

    getFilteredOptions = () => {
        return _.filter(this.state.options, option => {
            return (option.element || option.label) && !option.exclude
        })
    }

    updateMinWidth = () => {
        if (!this.trigger) return
        const minWidth = this.trigger.offsetWidth
        if (minWidth === this.state.minWidth) return
        this.setState({ minWidth })
    }
}
