import { IconBack, LinkButton } from '@percolate/ui'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import utilStyles from 'lib/util.less'

interface IProps {
    onClick(): void
}

export function BackButton({ onClick }: IProps) {
    return (
        <div className={utilStyles.pushTopL} style={{ textAlign: 'center' }}>
            <LinkButton
                className={utilStyles.backButton}
                hideLabel
                IconLeft={IconBack}
                label={i18next.t('PERC_Go_Back', { defaultValue: enUS['PERC_Go_Back'] })}
                onClick={onClick}
            />
        </div>
    )
}
