import { Button } from '@percolate/ui'
import classNames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import logoOrange from 'images/logo.png'
import { Error } from 'lib/components/error'
import { RedirectToUrl } from 'lib/components/redirect_to_url'
import { Typography } from 'lib/components/typography'
import styles from 'lib/grant.less'
import { ICapability, IContext, IEventSubmitGrant } from 'lib/typing'
import utilStyles from 'lib/util.less'
import { groupBy, head, isEmpty, map } from 'lodash'
import querystring from 'querystring'
import { Component } from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<IContext, 'capabilities' | 'clientName' | 'error' | 'grant' | 'state'>
    matches(stateValue: StateValue): boolean
    submitGrant(e: IEventSubmitGrant): void
}

export class Grant extends Component<IProps> {
    onSubmit = () => {
        this.props.submitGrant({ type: 'submitGrant' })
    }

    render() {
        const { context, matches } = this.props
        const disabled = matches({ grant: 'submitting' }) || matches({ grant: 'redirectingToClient' })
        return (
            <div className={styles.root}>
                {matches({ grant: 'redirectingToClient' }) && (
                    <RedirectToUrl
                        url={getClientUrl({
                            grant: context.grant!,
                            state: context.state,
                        })}
                    />
                )}
                <div className={styles.content}>
                    <div className={styles.body}>
                        <div className={styles.logo}>
                            <img alt="Percolate Logo" src={logoOrange} />
                        </div>
                        <Typography align="center" type="header1">
                            {i18next.t('PERC_Pident_Access_Info', {
                                defaultValue: enUS['PERC_Pident_Access_Info'],
                            })}
                        </Typography>
                        <div className={styles.clientName}>{context.clientName}</div>
                        {!isEmpty(context.capabilities) && (
                            <>
                                {map(
                                    groupBy(context.capabilities, (capability: ICapability) =>
                                        head(capability.code.split(':'))
                                    ),
                                    (capabilities: ICapability[], group: string) => (
                                        <Typography key={group} type="paragraph">
                                            {map(capabilities, 'name').join(', ')}
                                        </Typography>
                                    )
                                )}
                            </>
                        )}
                    </div>
                    <div className={styles.footer}>
                        {context.error && <Error error={context.error} />}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Typography
                                align="right"
                                className={classNames(utilStyles.flush, utilStyles.muted)}
                                type="paragraph"
                            >
                                {i18next.t('PERC_Pident_Authorize', {
                                    defaultValue: enUS['PERC_Pident_Authorize'],
                                })}
                            </Typography>
                            <Button
                                className={classNames(utilStyles.pushLeftL, utilStyles.pushTopM)}
                                disabled={disabled}
                                label={i18next.t('PERC_Authorize', { defaultValue: enUS['PERC_Authorize'] })}
                                onClick={this.onSubmit}
                                variant="primary"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function getClientUrl({ grant, state }: Required<Pick<IContext, 'grant' | 'state'>>): string {
    return [
        grant.ext.callbackUrl,
        querystring.stringify({
            client_id: grant.ext.clientId,
            state,
            code: grant.ext.code,
        }),
    ].join('?')
}
