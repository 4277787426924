import { Button, IconCircleQuestion, LinkButton, Tooltip } from '@percolate/ui'
import classNames from 'classnames'
import { location } from 'common/location'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { Error } from 'lib/components/error'
import { Form } from 'lib/components/form'
import { InputField } from 'lib/components/input_field'
import { RedirectToUrl } from 'lib/components/redirect_to_url'
import { Typography } from 'lib/components/typography'
import { IContext, IEventStartEmail, IEventSubmitOrigin } from 'lib/typing'
import utilStyles from 'lib/util.less'
import { Component } from 'react'
import { StateValue } from 'xstate'

interface IProps {
    context: Pick<IContext, 'error' | 'origin'>
    matches(stateValue: StateValue): boolean
    startEmail(e: IEventStartEmail): void
    submitOrigin(e: IEventSubmitOrigin): void
}
interface IState {
    origin: string
}

export class Origin extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            origin: props.context.origin || '',
        }
    }

    onChangeOrigin = (origin: string) => {
        this.setState({ origin })
    }

    onStartEmail = () => {
        this.props.startEmail({ type: 'startEmail' })
    }

    onSubmit = () => {
        this.props.submitOrigin({
            type: 'submitOrigin',
            payload: {
                origin: this.state.origin,
            },
        })
    }

    render() {
        const { context, matches } = this.props
        const disabled = matches({ origin: 'submitting' }) || matches({ origin: 'redirectingToApp' })
        return (
            <>
                {matches({ origin: 'redirectingToApp' }) && <RedirectToUrl url={getUrl(context.origin)} />}
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Sign_In', { defaultValue: enUS['PERC_Sign_In'] })}
                </Typography>
                <Typography align="center" type="paragraph">
                    {i18next.t('PERC_Pident_Enter_Subdomain', {
                        defaultValue: enUS['PERC_Pident_Enter_Subdomain'],
                    })}
                    <Tooltip
                        content={i18next.t('PERC_Pident_Enter_Subdomain_Tooltip', {
                            defaultValue: enUS['PERC_Pident_Enter_Subdomain_Tooltip'],
                        })}
                        position="bottom"
                        style={{ maxWidth: 200 }}
                    >
                        <IconCircleQuestion />
                    </Tooltip>
                </Typography>
                {context.error && <Error error={context.error} />}
                <Form onSubmit={this.onSubmit}>
                    <InputField
                        autoFocus
                        disabled={disabled}
                        endAdornment=".percolate.com"
                        label={i18next.t('PERC_Pident_Company_URL', {
                            defaultValue: enUS['PERC_Pident_Company_URL'],
                        })}
                        onChange={this.onChangeOrigin}
                        placeholder={i18next.t('PERC_Pident_Subdomain', {
                            defaultValue: enUS['PERC_Pident_Subdomain'],
                        })}
                        type="text"
                        value={this.state.origin}
                    />
                    <Button
                        type="submit"
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        disabled={disabled}
                        label={i18next.t('PERC_Continue', { defaultValue: enUS['PERC_Continue'] })}
                        variant="primary"
                    />
                </Form>
                <LinkButton
                    className={classNames(utilStyles.pushTopL, utilStyles.formButton, utilStyles.textButton)}
                    label={
                        i18next.t('PERC_Sign_In_To', { defaultValue: enUS['PERC_Sign_In_To'] }) +
                        location.host
                    }
                    onClick={this.onStartEmail}
                />
            </>
        )
    }
}

function getUrl(subdomain?: string) {
    // eslint-disable-next-line no-restricted-properties
    return `https://${subdomain ? subdomain + '.' : ''}percolate.com/app/login${window.location.search}`
}
