/* eslint react/no-multi-comp: "warn" */
import { KEYS } from 'apps/constants/keys'
import styles from 'apps/modal/modal.less'
import classNames from 'classnames'
import { noop, uniqueId } from 'lodash-es'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { createPortal } from 'react-dom'

export default class Modal extends Component {
    static propTypes = {
        preventCloseOnEsc: PropTypes.bool,
        onClose: PropTypes.func,
        className: PropTypes.string,
        children: PropTypes.any,
        noBackground: PropTypes.bool,
    }

    static defaultProps = {
        onClose: noop,
    }

    constructor() {
        super()
        const id = uniqueId('apps/modal/modal.jsx-')
        this.state = {
            bodyClass: ['body-overflow-hider-react-', id].join(''),
        }
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('keydown', this.onKeydown, false)
        document.body.classList.add(this.state.bodyClass)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeydown, false)
        document.body.classList.remove(this.state.bodyClass)
    }

    onKeydown = e => {
        if (this.props.preventCloseOnEsc) return false
        switch (e.keyCode) {
            case KEYS.ESC:
                return this.props.onClose()
        }
    }

    render() {
        return createPortal(
            <div
                className={classNames(styles.modal, this.props.className, {
                    [styles.noBackground]: this.props.noBackground,
                })}
            >
                {this.props.children}
            </div>,
            document.body
        )
    }
}
