// react key values adhere to keys event keys listed here
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
export const KEYS = {
    BACKSPACE: 'Backspace',
    TAB: 'Tab',
    ENTER: 'Enter',
    ESC: 'Escape',
    LEFT_ARROW: 'ArrowLeft',
    UP_ARROW: 'ArrowUp',
    RIGHT_ARROW: 'ArrowRight',
    DOWN_ARROW: 'ArrowDown',
}
