import Bluebird from 'bluebird'
import { AuthTransportError } from 'lib/auth_transport_error'
import {
    createAuthorization,
    createGrant,
    createLegacySsoAuthorization,
    createPassword,
    deletePassword,
} from 'lib/client'
import { ERRORS, IDP_APP_LOGIN_URL, SESSIONS } from 'lib/constants'
import { IContext, IEvent, IEventBootstrap } from 'lib/typing'
import { find, get, isEmpty } from 'lodash'
import { assign, Machine, Sender } from 'xstate'

export enum IEntryState {
    CreatePassword,
    Email,
    Grant,
    InitializingSso,
    Redirecting,
    ResetPassword,
    Saml2Redirect,
    Tenant,
    IdpApp,
}

export const services = {
    createAuthorizationByConsentService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createAuthorization({
            appClientId: ctx.appClientId,
            consent: ctx.consent,
            email: ctx.email,
            otp: ctx.otp,
            password: ctx.password,
            session: ctx.session,
            ssoPayload: ctx.ssoPayload,
            tenantId: ctx.tenantId,
        })
            .then(() => {
                if (isNeedsGrant(ctx)) {
                    return callback({ type: 'needsGrant' })
                } else {
                    return callback({ type: 'authenticated' })
                }
            })
            .error((error: AuthTransportError) => {
                switch (error.data.code) {
                    case ERRORS.invalidCredentials:
                        return callback({ type: 'deniedConsent' })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
    createAuthorizationByEmailService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        if (isEmpty(ctx.email)) {
            return Bluebird.resolve().then(() =>
                callback({
                    type: 'error',
                    payload: {
                        error: new AuthTransportError({ errors: [{ code: ERRORS.emptyEmail }] }),
                    },
                })
            )
        }
        return createAuthorization({
            appClientId: ctx.appClientId,
            email: ctx.email,
            session: ctx.session,
        })
            .then(() => {
                return callback({
                    type: 'error',
                    payload: {
                        error: new Error('Invalid response status from createAuthorizationByEmailService'),
                    },
                })
            })
            .error((error: AuthTransportError) => {
                switch (error.data.code) {
                    case ERRORS.isSsoUser:
                        return callback({ type: 'needsIdp' })
                    case ERRORS.invalidCredentials:
                        return callback({ type: 'needsPassword' })
                    case ERRORS.tenantIdRequired:
                        return callback({
                            type: 'needsTenant',
                            payload: {
                                tenants: error.data.tenants,
                            },
                        })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
    createAuthorizationByOtpService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createAuthorization({
            appClientId: ctx.appClientId,
            email: ctx.email,
            otp: ctx.otp,
            password: ctx.password,
            session: ctx.session,
            ssoPayload: ctx.ssoPayload,
            tenantId: ctx.tenantId,
        })
            .then(() => {
                if (isNeedsGrant(ctx)) {
                    return callback({ type: 'needsGrant' })
                } else if (isNeedsIdpApp(ctx)) {
                    return callback({
                        type: 'needsIdpApp',
                    })
                } else {
                    return callback({ type: 'authenticated' })
                }
            })
            .error(error => {
                switch (error.data.code) {
                    case ERRORS.consentRequired:
                        return callback({
                            type: 'needsGdprConsent',
                            payload: {
                                consentTerms: error.data.consentTerms,
                            },
                        })
                    case ERRORS.passwordLoginLocked:
                        return callback({ type: 'locked' })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
    createAuthorizationByPasswordService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createAuthorization({
            appClientId: ctx.appClientId,
            email: ctx.email,
            password: ctx.password,
            session: ctx.session,
        })
            .then(() => {
                if (isNeedsGrant(ctx)) {
                    return callback({ type: 'needsGrant' })
                } else if (isNeedsIdpApp(ctx)) {
                    return callback({
                        type: 'needsIdpApp',
                    })
                } else {
                    return callback({ type: 'authenticated' })
                }
            })
            .error((error: AuthTransportError) => {
                switch (error.data.code) {
                    case ERRORS.consentRequired:
                        return callback({
                            type: 'needsGdprConsent',
                            payload: {
                                consentTerms: error.data.consentTerms,
                            },
                        })
                    case ERRORS.otpRequired:
                        return callback({ type: 'needsTwoFactorAuthCode' })
                    case ERRORS.passwordLoginLocked:
                        return callback({ type: 'locked' })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
    createAuthorizationBySsoService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createAuthorization({
            appClientId: ctx.appClientId,
            email: ctx.email,
            session: ctx.session,
            ssoPayload: ctx.ssoPayload,
            tenantId: ctx.tenantId,
        })
            .then(() => {
                if (isNeedsGrant(ctx)) {
                    return callback({ type: 'needsGrant' })
                } else if (isNeedsIdpApp(ctx)) {
                    return callback({
                        type: 'needsIdpApp',
                    })
                } else {
                    return callback({ type: 'authenticated' })
                }
            })
            .error(error => {
                switch (error.data.code) {
                    case ERRORS.consentRequired:
                        return callback({
                            type: 'needsGdprConsent',
                            payload: {
                                consentTerms: error.data.consentTerms,
                            },
                        })
                    case ERRORS.otpRequired:
                        return callback({ type: 'needsTwoFactorAuthCode' })
                    case ERRORS.autoProvisionSso:
                        return callback({ type: 'needsLegacyAuthSso' })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
    createAuthorizationByTenantService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createAuthorization({
            appClientId: ctx.appClientId,
            email: ctx.email,
            password: ctx.password,
            session: ctx.session,
            tenantId: ctx.tenantId,
        })
            .then(() => {
                if (isNeedsGrant(ctx)) {
                    return callback({ type: 'needsGrant' })
                } else if (isNeedsIdpApp(ctx)) {
                    return callback({
                        type: 'needsIdpApp',
                    })
                } else {
                    return callback({ type: 'authenticated' })
                }
            })
            .error((error: AuthTransportError) => {
                switch (error.data.code) {
                    case ERRORS.consentRequired:
                        return callback({
                            type: 'needsGdprConsent',
                            payload: {
                                consentTerms: error.data.consentTerms,
                            },
                        })
                    case ERRORS.otpRequired:
                        return callback({ type: 'needsTwoFactorAuthCode' })
                    case ERRORS.passwordLoginLocked:
                        return callback({ type: 'locked' })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
    createGrantService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createGrant({
            appClientId: ctx.appClientId,
            clientId: ctx.clientId,
            email: ctx.email,
        })
            .then(grant =>
                callback({
                    type: 'grantSuccess',
                    payload: {
                        grant,
                    },
                })
            )
            .error(error => callback({ type: 'error', payload: { error } }))
    },
    createLegacySsoAuthorizationService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createLegacySsoAuthorization({
            ssoPayload: ctx.ssoPayload,
            v3AuthApiToken: ctx.v3AuthApiToken,
        })
            .then(res =>
                callback({
                    type: 'legacyAuthSsoSuccess',
                    payload: {
                        email: res.email,
                    },
                })
            )
            .error(error => callback({ type: 'error', payload: { error } }))
    },
    createPasswordService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createPassword({
            appClientId: ctx.appClientId,
            password: ctx.password,
            tenantId: ctx.tenantId,
            token: ctx.token,
            userId: ctx.userId,
        })
            .then(() => callback({ type: 'createPasswordSuccess' }))
            .error(error => callback({ type: 'error', payload: { error } }))
    },
    fetchTenantsService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createAuthorization({
            appClientId: ctx.appClientId,
            email: ctx.email,
            session: ctx.session,
        })
            .then(() => {
                return callback({
                    type: 'error',
                    payload: {
                        error: new Error('Invalid response status from fetchTenantsService'),
                    },
                })
            })
            .error((error: AuthTransportError) => {
                switch (error.data.code) {
                    case ERRORS.tenantIdRequired:
                        return callback({
                            type: 'fetchedTenants',
                            payload: {
                                tenants: error.data.tenants,
                            },
                        })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
    originService:
        ({ origin }: IContext) =>
        (callback: Sender<IEvent>) => {
            return Bluebird.resolve().then(() => {
                if (!isEmpty(origin) && !/^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/.test(origin!)) {
                    callback({
                        type: 'error',
                        payload: {
                            error: new AuthTransportError({ errors: [{ code: ERRORS.invalidSubdomain }] }),
                        },
                    })
                } else {
                    callback({ type: 'originSuccess' })
                }
            })
        },
    resetPasswordService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return deletePassword({
            appClientId: ctx.appClientId,
            email: ctx.email,
            tenantId: ctx.tenantId,
        })
            .then(() => callback({ type: 'resetPasswordSuccess' }))
            .error(error => callback({ type: 'error', payload: { error } }))
    },
    tryCreateAuthorizationByTenantService: (ctx: IContext) => (callback: Sender<IEvent>) => {
        return createAuthorization({
            appClientId: ctx.appClientId,
            email: ctx.email,
            session: ctx.session,
            tenantId: ctx.tenantId,
        })
            .then(() => callback({ type: 'selectedAuthorizedTenant' }))
            .error((error: AuthTransportError) => {
                switch (error.data.code) {
                    case ERRORS.isSsoUser:
                        return callback({ type: 'selectedSsoTenant' })
                    case ERRORS.invalidCredentials:
                        return callback({ type: 'selectedNonSsoTenant' })
                    case ERRORS.tenantResetPasswordRequired:
                        return callback({ type: 'selectedResetPasswordTenant' })
                    default:
                        return callback({ type: 'error', payload: { error } })
                }
            })
    },
}

export const machine = Machine<IContext, any, IEvent>(
    {
        id: 'root',
        initial: '_entry',
        context: {
            session: SESSIONS.long,
        },
        states: {
            _entry: {
                on: {
                    bootstrap: {
                        actions: 'bootstrap',
                    },
                    start: [
                        {
                            cond: 'entryIsCreatePassword',
                            target: 'createPassword',
                        },
                        {
                            cond: 'entryIsEmail',
                            target: 'email',
                        },
                        {
                            cond: 'entryIsGrant',
                            target: 'grant',
                        },
                        {
                            cond: 'entryIsInitializingSso',
                            target: 'initializingSso',
                        },
                        {
                            cond: 'entryIsRedirecting',
                            target: 'redirectingToApp',
                        },
                        {
                            cond: 'entryIsResetPassword',
                            target: 'resetPassword',
                        },
                        {
                            cond: 'entryIsSaml2Redirect',
                            target: 'redirectingSaml2',
                        },
                        {
                            cond: 'entryIsTenant',
                            target: 'tenant',
                        },
                        {
                            cond: 'entryIsIdpApp',
                            actions: 'updateIdpAppRedirectUrl',
                            target: 'redirectingToApp',
                        },
                    ],
                },
            },
            createPassword: {
                initial: 'ok',
                states: {
                    ok: {
                        on: {
                            submitPassword: {
                                actions: ['removeError', 'updatePassword'],
                                target: 'submitting',
                            },
                            startResetPassword: {
                                actions: ['removeError', 'resetPartial'],
                                target: '#root.resetPassword',
                            },
                        },
                    },
                    submitting: {
                        invoke: {
                            src: 'createPasswordService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            createPasswordSuccess: 'success',
                        },
                    },
                    success: {
                        on: {
                            startEmail: {
                                actions: 'resetComplete',
                                target: '#root.email',
                            },
                        },
                    },
                },
            },
            email: {
                initial: 'ok',
                on: {
                    startResetPassword: '#root.resetPassword',
                    startOrigin: '#root.origin',
                },
                states: {
                    ok: {
                        on: {
                            submitEmail: {
                                actions: ['removeError', 'updateEmail'],
                                target: 'submitting',
                            },
                        },
                    },
                    redirectingToIdp: {
                        type: 'final',
                    },
                    submitting: {
                        invoke: {
                            src: 'createAuthorizationByEmailService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            needsIdp: 'redirectingToIdp',
                            needsPassword: '#root.password',
                            needsTenant: {
                                actions: 'updateTenants',
                                target: '#root.tenant',
                            },
                        },
                    },
                },
            },
            gdprConsent: {
                initial: 'ok',
                states: {
                    ok: {
                        on: {
                            submitGdprConsent: {
                                actions: ['removeError', 'updateConsent'],
                                target: 'submittingGdprConsent',
                            },
                        },
                    },
                    redirectingToApp: {
                        type: 'final',
                    },
                    submittingGdprConsent: {
                        invoke: {
                            src: 'createAuthorizationByConsentService',
                        },
                        on: {
                            authenticated: 'redirectingToApp',
                            deniedConsent: {
                                actions: 'resetComplete',
                                target: '#root.email',
                            },
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            needsGrant: '#root.grant',
                        },
                    },
                },
            },
            grant: {
                initial: 'ok',
                states: {
                    ok: {
                        on: {
                            submitGrant: {
                                actions: 'removeError',
                                target: 'subittingGrant',
                            },
                        },
                    },
                    redirectingToClient: {
                        type: 'final',
                    },
                    subittingGrant: {
                        invoke: {
                            src: 'createGrantService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            grantSuccess: {
                                actions: 'updateGrant',
                                target: 'redirectingToClient',
                            },
                        },
                    },
                },
            },
            initializingSso: {
                initial: 'submitting',
                states: {
                    error: {
                        type: 'final',
                    },
                    submitting: {
                        invoke: {
                            src: 'createAuthorizationBySsoService',
                        },
                        on: {
                            authenticated: '#root.redirectingToApp',
                            error: {
                                actions: 'updateError',
                                target: 'error',
                            },
                            needsGdprConsent: {
                                actions: 'updateConsentTerms',
                                target: '#root.gdprConsent',
                            },
                            needsGrant: '#root.grant',
                            needsLegacyAuthSso: 'submittingLegacyAuth',
                            needsTwoFactorAuthCode: '#root.twoFactorAuthCode',
                            needsIdpApp: {
                                actions: 'updateIdpAppRedirectUrl',
                                target: '#root.redirectingToApp',
                            },
                        },
                    },
                    submittingLegacyAuth: {
                        invoke: {
                            src: 'createLegacySsoAuthorizationService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'error',
                            },
                            legacyAuthSsoSuccess: {
                                actions: 'updateEmail',
                                target: 'submitting',
                            },
                        },
                    },
                },
            },
            locked: {
                initial: 'ok',
                states: {
                    ok: {
                        on: {
                            submitResetPasswordEmail: {
                                actions: ['removeError', 'updateEmail'],
                                target: 'submitting',
                            },
                        },
                    },
                    submitting: {
                        invoke: {
                            src: 'resetPasswordService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            resetPasswordSuccess: 'success',
                        },
                    },
                    success: {
                        type: 'final',
                        on: {
                            startEmail: {
                                actions: 'resetComplete',
                                target: '#root.email',
                            },
                        },
                    },
                },
            },
            origin: {
                initial: 'ok',
                on: {
                    startEmail: {
                        actions: 'resetComplete',
                        target: '#root.email',
                    },
                },
                states: {
                    ok: {
                        on: {
                            submitOrigin: {
                                actions: ['removeError', 'updateOrigin'],
                                target: 'submitting',
                            },
                        },
                    },
                    redirectingToApp: {
                        type: 'final',
                    },
                    submitting: {
                        invoke: {
                            src: 'originService',
                        },
                        on: {
                            originSuccess: 'redirectingToApp',
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                        },
                    },
                },
            },
            password: {
                initial: 'ok',
                on: {
                    startEmail: {
                        actions: 'resetComplete',
                        target: '#root.email',
                    },
                    startResetPassword: {
                        actions: 'resetPartial',
                        target: '#root.resetPassword',
                    },
                },
                states: {
                    ok: {
                        on: {
                            submitPassword: {
                                actions: ['removeError', 'updatePassword'],
                                target: 'submitting',
                            },
                        },
                    },
                    redirectingToApp: {
                        type: 'final',
                    },
                    submitting: {
                        invoke: {
                            src: 'createAuthorizationByPasswordService',
                        },
                        on: {
                            authenticated: 'redirectingToApp',
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            locked: {
                                actions: 'resetPartial',
                                target: '#root.locked',
                            },
                            needsGdprConsent: {
                                actions: 'updateConsentTerms',
                                target: '#root.gdprConsent',
                            },
                            needsGrant: '#root.grant',
                            needsTwoFactorAuthCode: '#root.twoFactorAuthCode',
                            needsIdpApp: {
                                actions: 'updateIdpAppRedirectUrl',
                                target: 'redirectingToApp',
                            },
                        },
                    },
                },
            },
            redirectingToApp: {
                type: 'final',
            },
            redirectingSaml2: {
                type: 'final',
            },
            resetPassword: {
                initial: 'ok',
                states: {
                    ok: {
                        on: {
                            submitResetPasswordEmail: {
                                actions: ['removeError', 'updateEmail'],
                                target: 'submitting',
                            },
                        },
                    },
                    submitting: {
                        invoke: {
                            src: 'resetPasswordService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            resetPasswordSuccess: 'success',
                        },
                    },
                    success: {
                        on: {
                            startEmail: {
                                actions: 'resetComplete',
                                target: '#root.email',
                            },
                        },
                    },
                },
            },
            tenant: {
                initial: '_entry',
                on: {
                    startEmail: {
                        actions: 'resetComplete',
                        target: '#root.email',
                    },
                },
                states: {
                    _entry: {
                        on: {
                            '': [
                                {
                                    cond: 'hasTenants',
                                    target: 'submittingSelectedTenant',
                                },
                                {
                                    cond: 'notHasTenants',
                                    target: 'fetchingTenants',
                                },
                            ],
                        },
                    },
                    error: {},
                    fetchingTenants: {
                        invoke: {
                            src: 'fetchTenantsService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'error',
                            },
                            fetchedTenants: {
                                actions: 'updateTenants',
                                target: 'submittingSelectedTenant',
                            },
                        },
                    },
                    selectedAuthorizedTenant: {
                        initial: 'ok',
                        states: {
                            ok: {
                                on: {
                                    complete: 'redirectingToApp',
                                    submitTenantId: {
                                        actions: 'udpateTenantId',
                                        target: 'submittingSelectedTenant',
                                    },
                                },
                            },
                            redirectingToApp: {
                                type: 'final',
                            },
                            submittingSelectedTenant: {
                                invoke: {
                                    src: 'tryCreateAuthorizationByTenantService',
                                },
                                on: {
                                    error: {
                                        actions: 'updateError',
                                        target: 'ok',
                                    },
                                    selectedAuthorizedTenant: '#root.tenant.selectedAuthorizedTenant',
                                    selectedResetPasswordTenant: '#root.tenant.selectedResetPasswordTenant',
                                    selectedNonSsoTenant: '#root.tenant.selectedNonSsoTenant',
                                    selectedSsoTenant: '#root.tenant.selectedSsoTenant',
                                },
                            },
                        },
                    },
                    selectedResetPasswordTenant: {
                        initial: 'ok',
                        states: {
                            ok: {
                                on: {
                                    startResetPassword: {
                                        actions: 'resetPartial',
                                        target: '#root.resetPassword',
                                    },
                                    submitTenantId: {
                                        actions: 'udpateTenantId',
                                        target: 'submittingSelectedTenant',
                                    },
                                },
                            },
                            submittingSelectedTenant: {
                                invoke: {
                                    src: 'tryCreateAuthorizationByTenantService',
                                },
                                on: {
                                    error: {
                                        actions: 'updateError',
                                        target: 'ok',
                                    },
                                    selectedAuthorizedTenant: '#root.tenant.selectedAuthorizedTenant',
                                    selectedResetPasswordTenant: '#root.tenant.selectedResetPasswordTenant',
                                    selectedNonSsoTenant: '#root.tenant.selectedNonSsoTenant',
                                    selectedSsoTenant: '#root.tenant.selectedSsoTenant',
                                },
                            },
                        },
                    },
                    selectedNonSsoTenant: {
                        initial: 'ok',
                        states: {
                            ok: {
                                on: {
                                    startResetPassword: {
                                        actions: 'resetPartial',
                                        target: '#root.resetPassword',
                                    },
                                    submitPassword: {
                                        actions: ['removeError', 'updatePassword'],
                                        target: 'submitting',
                                    },
                                    submitTenantId: {
                                        actions: 'udpateTenantId',
                                        target: 'submittingSelectedTenant',
                                    },
                                },
                            },
                            submitting: {
                                invoke: {
                                    src: 'createAuthorizationByTenantService',
                                },
                                on: {
                                    authenticated: 'redirectingToApp',
                                    error: {
                                        actions: 'updateError',
                                        target: 'ok',
                                    },
                                    locked: {
                                        actions: 'resetPartial',
                                        target: '#root.locked',
                                    },
                                    needsGdprConsent: {
                                        actions: 'updateConsentTerms',
                                        target: '#root.gdprConsent',
                                    },
                                    needsGrant: '#root.grant',
                                    needsTwoFactorAuthCode: '#root.twoFactorAuthCode',
                                    needsIdpApp: {
                                        actions: 'updateIdpAppRedirectUrl',
                                        target: 'redirectingToApp',
                                    },
                                },
                            },
                            redirectingToApp: {
                                type: 'final',
                            },
                            submittingSelectedTenant: {
                                invoke: {
                                    src: 'tryCreateAuthorizationByTenantService',
                                },
                                on: {
                                    error: {
                                        actions: 'updateError',
                                        target: 'ok',
                                    },
                                    selectedAuthorizedTenant: '#root.tenant.selectedAuthorizedTenant',
                                    selectedResetPasswordTenant: '#root.tenant.selectedResetPasswordTenant',
                                    selectedNonSsoTenant: '#root.tenant.selectedNonSsoTenant',
                                    selectedSsoTenant: '#root.tenant.selectedSsoTenant',
                                },
                            },
                        },
                    },
                    selectedSsoTenant: {
                        initial: 'ok',
                        states: {
                            ok: {
                                on: {
                                    complete: 'redirectingToIdp',
                                    submitTenantId: {
                                        actions: 'udpateTenantId',
                                        target: 'submittingSelectedTenant',
                                    },
                                },
                            },
                            redirectingToIdp: {
                                type: 'final',
                            },
                            submittingSelectedTenant: {
                                invoke: {
                                    src: 'tryCreateAuthorizationByTenantService',
                                },
                                on: {
                                    error: {
                                        actions: 'updateError',
                                        target: 'ok',
                                    },
                                    selectedAuthorizedTenant: '#root.tenant.selectedAuthorizedTenant',
                                    selectedResetPasswordTenant: '#root.tenant.selectedResetPasswordTenant',
                                    selectedNonSsoTenant: '#root.tenant.selectedNonSsoTenant',
                                    selectedSsoTenant: '#root.tenant.selectedSsoTenant',
                                },
                            },
                        },
                    },
                    submittingSelectedTenant: {
                        invoke: {
                            src: 'tryCreateAuthorizationByTenantService',
                        },
                        on: {
                            error: {
                                actions: 'updateError',
                                target: 'error',
                            },
                            selectedAuthorizedTenant: 'selectedAuthorizedTenant',
                            selectedResetPasswordTenant: 'selectedResetPasswordTenant',
                            selectedNonSsoTenant: 'selectedNonSsoTenant',
                            selectedSsoTenant: 'selectedSsoTenant',
                        },
                    },
                },
            },
            twoFactorAuthCode: {
                initial: 'ok',
                states: {
                    ok: {
                        on: {
                            submitTwoFactorAuthCode: {
                                actions: ['removeError', 'updateOtp'],
                                target: 'submitting',
                            },
                        },
                    },
                    redirectingToApp: {
                        type: 'final',
                    },
                    submitting: {
                        invoke: {
                            src: 'createAuthorizationByOtpService',
                        },
                        on: {
                            authenticated: 'redirectingToApp',
                            error: {
                                actions: 'updateError',
                                target: 'ok',
                            },
                            locked: {
                                actions: 'resetPartial',
                                target: '#root.locked',
                            },
                            needsGdprConsent: {
                                actions: 'updateConsentTerms',
                                target: '#root.gdprConsent',
                            },
                            needsGrant: '#root.grant',
                            needsIdpApp: {
                                actions: 'updateIdpAppRedirectUrl',
                                target: 'redirectingToApp',
                            },
                        },
                    },
                },
            },
        },
    },
    {
        actions: {
            bootstrap: assign((ctx: IContext, { payload }: IEventBootstrap) => payload),
            removeError: assign({
                error: undefined,
            }),
            removePassword: assign({
                password: undefined,
            }),
            resetComplete: assign({
                consent: undefined,
                consentTerms: undefined,
                email: undefined,
                error: undefined,
                grant: undefined,
                origin: undefined,
                otp: undefined,
                otpSecretId: undefined,
                otpSecretSetupToken: undefined,
                password: undefined,
                qrCode: undefined,
                session: SESSIONS.long,
                tenantId: undefined,
                tenants: undefined,
            }),
            resetPartial: assign({
                consent: undefined,
                consentTerms: undefined,
                grant: undefined,
                origin: undefined,
                otp: undefined,
                otpSecretId: undefined,
                otpSecretSetupToken: undefined,
                password: undefined,
                qrCode: undefined,
                session: SESSIONS.long,
            }),
            updateConsent: assign({
                consent: (ctx: IContext, e: any) => e.payload.consent,
            }),
            updateConsentTerms: assign({
                consentTerms: (ctx: IContext, e: any) => e.payload.consentTerms,
            }),
            updateEmail: assign({
                email: (ctx: IContext, e: any) => e.payload.email,
                password: (ctx: IContext, e: any) => e.payload.password,
            }),
            updateError: assign({
                error: (ctx: IContext, e: any) => e.payload.error,
            }),
            updateGrant: assign({
                grant: (ctx: IContext, e: any) => e.payload.grant,
            }),
            updateIdpAppRedirectUrl: assign({
                overrideSuccessRedirectUrl: (ctx: IContext) =>
                    `${IDP_APP_LOGIN_URL}?idp_app_id=${ctx.idpAppId}&client_id=${ctx.clientId}`,
            }),
            updateOrigin: assign({
                origin: (ctx: IContext, e: any) => e.payload.origin,
            }),
            updateOtp: assign({
                otp: (ctx: IContext, e: any) => e.payload.otp,
            }),
            updatePassword: assign({
                password: (ctx: IContext, e: any) => e.payload.password,
            }),
            udpateTenantId: assign({
                password: undefined,
                tenantId: (ctx: IContext, e: any) => e.payload.tenantId,
            }),
            updateTenants: assign({
                tenantId: (ctx: IContext, e: any) =>
                    find(e.payload.tenants, { id: ctx.tenantId })
                        ? ctx.tenantId
                        : get(e.payload.tenants, [0, 'id']),
                tenants: (ctx: IContext, e: any) => e.payload.tenants,
            }),
        },
        guards: {
            entryIsCreatePassword: isEntryState(IEntryState.CreatePassword),
            entryIsEmail: isEntryState(IEntryState.Email),
            entryIsGrant: isEntryState(IEntryState.Grant),
            entryIsInitializingSso: isEntryState(IEntryState.InitializingSso),
            entryIsRedirecting: isEntryState(IEntryState.Redirecting),
            entryIsResetPassword: isEntryState(IEntryState.ResetPassword),
            entryIsSaml2Redirect: isEntryState(IEntryState.Saml2Redirect),
            entryIsTenant: isEntryState(IEntryState.Tenant),
            entryIsIdpApp: isEntryState(IEntryState.IdpApp),
            hasTenants: (ctx: IContext) => !isEmpty(ctx.tenants),
            notHasTenants: (ctx: IContext) => isEmpty(ctx.tenants),
        },
        services,
    }
)

function isEntryState(entry: IEntryState): (ctx: IContext) => boolean {
    return ctx => getEntryState(ctx) === entry
}

export function getEntryState(ctx: IContext): IEntryState | undefined {
    if (!isEmpty(ctx.appClientId) && !isEmpty(ctx.token) && !isEmpty(ctx.userId)) {
        return IEntryState.CreatePassword
    } else if (!isEmpty(ctx.appClientId) && ctx.resetPassword === true) {
        return IEntryState.ResetPassword
    } else if (!isEmpty(ctx.appClientId) && ctx.hasSession === true && !isEmpty(ctx.idpAppId)) {
        return IEntryState.IdpApp
    } else if (!isEmpty(ctx.appClientId) && !isEmpty(ctx.ssoPayload)) {
        return IEntryState.InitializingSso
    } else if (!isEmpty(ctx.saml2IdpUri) && !isEmpty(ctx.saml2Request) && !isEmpty(ctx.saml2Token)) {
        return IEntryState.Saml2Redirect
    } else if (
        !isEmpty(ctx.appClientId) &&
        ctx.hasSession === true &&
        !isEmpty(ctx.overrideSuccessRedirectUrl)
    ) {
        return IEntryState.Redirecting
    } else if (
        !isEmpty(ctx.appClientId) &&
        !isEmpty(ctx.capabilities) &&
        !isEmpty(ctx.clientId) &&
        !isEmpty(ctx.clientName) &&
        ctx.hasSession === true
    ) {
        return IEntryState.Grant
    } else if (!isEmpty(ctx.appClientId) && !isEmpty(ctx.email) && !isEmpty(ctx.tenantId)) {
        return IEntryState.Tenant
    } else if (!isEmpty(ctx.appClientId)) {
        return IEntryState.Email
    }
    throw new Error(`Invalid entry state: ${JSON.stringify(ctx)}`)
}

function isNeedsGrant(ctx: IContext) {
    return (
        !isEmpty(ctx.appClientId) &&
        !isEmpty(ctx.capabilities) &&
        !isEmpty(ctx.clientId) &&
        !isEmpty(ctx.clientName) &&
        isEmpty(ctx.overrideSuccessRedirectUrl)
    )
}

function isNeedsIdpApp(ctx: IContext) {
    return !isEmpty(ctx.idpAppId)
}
