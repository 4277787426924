import { camel, snake } from 'change-case'
import { IAnyObject } from 'lib/typing'
import _ from 'lodash'

export const fromServer = createNormalizer(camel, function (val) {
    return val === null ? undefined : val
})
export const toServer = createNormalizer(snake, function (val) {
    return val === undefined ? null : val
})

function createNormalizer(mapKey: (value: string) => string, mapVal: (value: any) => any) {
    return function (obj: IAnyObject | string) {
        if (typeof obj === 'string') return mapVal(obj)
        function walk(_obj: any): IAnyObject {
            if (!_obj || typeof _obj !== 'object') return mapVal(_obj)
            if (_.isArray(_obj)) return _.map(_obj, walk)
            return _.reduce<string, IAnyObject>(
                _.keys(_obj),
                function (acc, key) {
                    const _key = mapKey(key)
                    const val = walk(_obj[key])
                    acc[_key] = mapVal(val)
                    return acc
                },
                {}
            )
        }
        return walk(obj)
    }
}
