import { requestAnimationFrame } from 'apps/core/animation'
import styles from 'apps/dom_utils/resizer.less'
import PropTypes from 'prop-types'
import { Component } from 'react'

export default class Resizer extends Component {
    static propTypes = {
        children: PropTypes.node,
        onResize: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.lastDimensions = {
            width: null,
            height: null,
        }
    }

    componentDidMount() {
        this.resetTriggers()
    }

    componentDidUpdate() {
        this.resetTriggers()
    }

    resetTriggers = () => {
        const contract = this.contract
        const expandChild = this.expandChild
        const expand = this.expand

        if (!contract || !expand || !expandChild) return

        contract.scrollLeft = contract.scrollWidth
        contract.scrollTop = contract.scrollHeight
        expandChild.style.width = expand.offsetWidth + 1 + 'px'
        expandChild.style.height = expand.offsetHeight + 1 + 'px'
        expand.scrollLeft = expand.scrollWidth
        expand.scrollTop = expand.scrollHeight
    }

    onScroll = () => {
        if (this.r) return
        this.r = requestAnimationFrame(() => {
            delete this.r
            const dimensions = this.getDimensions()

            if (this.haveDimensionsChanged(dimensions)) {
                this.lastDimensions = dimensions
                this.props.onResize(dimensions)
            }
        })
    }

    getDimensions = () => {
        if (!this.wrapper) return {}
        return {
            width: this.wrapper.offsetWidth,
            height: this.wrapper.offsetHeight,
        }
    }

    haveDimensionsChanged = dimensions => {
        return (
            dimensions.width !== this.lastDimensions.width || dimensions.height !== this.lastDimensions.height
        )
    }

    render() {
        return (
            <div
                style={{ maxWidth: 'inherit', maxHeight: 'inherit' }}
                ref={wrapper => (this.wrapper = wrapper)}
                onScroll={this.onScroll}
            >
                {this.props.children}
                <div ref={trigger => (this.trigger = trigger)} className={styles.trigger}>
                    <div ref={expand => (this.expand = expand)} className={styles.expand}>
                        <div ref={expandChild => (this.expandChild = expandChild)} />
                    </div>
                    <div ref={contract => (this.contract = contract)} className={styles.contract} />
                </div>
            </div>
        )
    }
}
