import { Button, IconCircle, IconCircleCheck, IconCircleClose } from '@percolate/ui'
import classNames from 'classnames'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { Error } from 'lib/components/error'
import { Form } from 'lib/components/form'
import { InputField } from 'lib/components/input_field'
import { Typography } from 'lib/components/typography'
import { ERROR_MESSAGE_MAP, ERRORS } from 'lib/constants'
import { IContext, IEventStartEmail, IEventStartResetPassword, IEventSubmitPassword } from 'lib/typing'
import utilStyles from 'lib/util.less'
import { find, get, isEmpty, noop } from 'lodash'
import { Component } from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<IContext, 'error' | 'tenantId' | 'tenants'>
    matches(stateValue: StateValue): boolean
    startEmail(e: IEventStartEmail): void
    startResetPassword(e: IEventStartResetPassword): void
    submitPassword(e: IEventSubmitPassword): void
}
interface IState {
    password1: string
    password2: string
    password1Error?: string
    password2Error?: string
}

export class CreatePassword extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            password1: '',
            password2: '',
        }
    }

    onStartResetPassword = () => {
        this.props.startResetPassword({ type: 'startResetPassword' })
    }

    onChangePassword1 = (password1: string) => {
        this.setState({ password1 })
    }

    onChangePassword2 = (password2: string) => {
        this.setState({
            password2,
            ...(passwordsMatch(this.state.password1, password2) ? { password2Error: undefined } : {}),
        })
    }

    onStartEmail = () => {
        this.props.startEmail({ type: 'startEmail' })
    }

    onSubmit = () => {
        const password1Error = validatePassword1(this.state.password1)
        const password2Error = validatePassword2(this.state.password1, this.state.password2)
        this.setState({
            password1Error,
            password2Error,
        })
        if (!isEmpty(password1Error) || !isEmpty(password2Error)) {
            return
        }
        this.props.submitPassword({
            type: 'submitPassword',
            payload: {
                password: this.state.password1,
            },
        })
    }

    render() {
        const { context, matches } = this.props
        const { password1, password1Error, password2, password2Error } = this.state
        if (matches({ createPassword: 'success' })) {
            return (
                <>
                    <Typography align="center" type="header1">
                        {i18next.t('PERC_App_Toast_Title_Success', {
                            defaultValue: enUS['PERC_App_Toast_Title_Success'],
                        })}
                    </Typography>
                    <Typography align="center" type="paragraph">
                        {i18next.t('PERC_Pident_Password_Saved', {
                            defaultValue: enUS['PERC_Pident_Password_Saved'],
                        })}
                    </Typography>
                    <Button
                        className={classNames(utilStyles.pushTopXxl, utilStyles.formButton)}
                        label={i18next.t('PERC_Pident_Return_To_Sign_In', {
                            defaultValue: enUS['PERC_Pident_Return_To_Sign_In'],
                        })}
                        onClick={this.onStartEmail}
                        variant="primary"
                    />
                </>
            )
        }
        const disabled = matches({ createPassword: 'submitting' })
        const tenantName = get(find(context.tenants, { id: context.tenantId }), 'name')
        return (
            <>
                <Typography align="center" type="header1">
                    {i18next.t('PERC_Pident_Create_Password', {
                        defaultValue: enUS['PERC_Pident_Create_Password'],
                    })}
                </Typography>
                <Typography align="center" type="paragraph">
                    {i18next.t('PERC_Pident_Create_Password_Percolate', {
                        defaultValue: enUS['PERC_Pident_Create_Password_Percolate'],
                    })}
                </Typography>
                <Typography className={utilStyles.fontWeightBold} type="paragraph">
                    {i18next.t('PERC_Pident_Password_Must', {
                        defaultValue: enUS['PERC_Pident_Password_Must'],
                    })}
                </Typography>
                <Typography type="paragraph">
                    <Typography type="paragraph" className={utilStyles.flush}>
                        <ReqIcon password={password1} validator={validLength} />
                        {i18next.t('PERC_Pident_Password_Character_Length', {
                            defaultValue: enUS['PERC_Pident_Password_Character_Length'],
                        })}
                    </Typography>
                    <Typography type="paragraph" className={utilStyles.flush}>
                        <ReqIcon password={password1} validator={containsUppercase} />
                        {i18next.t('PERC_Pident_Password_Uppercase', {
                            defaultValue: enUS['PERC_Pident_Password_Uppercase'],
                        })}
                    </Typography>
                    <Typography type="paragraph" className={utilStyles.flush}>
                        <ReqIcon password={password1} validator={containsLowercase} />
                        {i18next.t('PERC_Pident_Password_Lowercase', {
                            defaultValue: enUS['PERC_Pident_Password_Lowercase'],
                        })}
                    </Typography>
                    <Typography type="paragraph" className={utilStyles.flush}>
                        <ReqIcon password={password1} validator={containsNumber} />
                        {i18next.t('PERC_Pident_Password_Numbers', {
                            defaultValue: enUS['PERC_Pident_Password_Numbers'],
                        })}
                    </Typography>
                    <Typography type="paragraph" className={utilStyles.flush}>
                        <ReqIcon password={password1} validator={containsSymbol} />
                        {i18next.t('PERC_Pident_Password_Symbols', {
                            defaultValue: enUS['PERC_Pident_Password_Symbols'],
                        })}
                    </Typography>
                </Typography>
                {context.error && <Error error={context.error} resetPassword={this.onStartResetPassword} />}
                <Form onSubmit={this.onSubmit}>
                    {!isEmpty(tenantName) && (
                        <InputField
                            disabled
                            label={i18next.t('PERC_Organization', {
                                defaultValue: enUS['PERC_Organization'],
                            })}
                            onChange={noop}
                            type="text"
                            value={tenantName!}
                        />
                    )}
                    <InputField
                        autoFocus
                        disabled={disabled}
                        error={password1Error}
                        label={i18next.t('PERC_Settings_Enter_Password', {
                            defaultValue: enUS['PERC_Settings_Enter_Password'],
                        })}
                        onChange={this.onChangePassword1}
                        placeholder={i18next.t('PERC_Password', { defaultValue: enUS['PERC_Password'] })}
                        type="password"
                        value={password1}
                    />
                    <InputField
                        disabled={disabled}
                        endAdornment={<MatchIcon password1={password1} password2={password2} />}
                        error={password2Error}
                        label={i18next.t('PERC_Pident_Confirm_Password', {
                            defaultValue: enUS['PERC_Pident_Confirm_Password'],
                        })}
                        onChange={this.onChangePassword2}
                        onBlur={() =>
                            this.setState({ password2Error: validatePassword2(password1, password2) })
                        }
                        placeholder={i18next.t('PERC_Pident_Reenter_Password', {
                            defaultValue: enUS['PERC_Pident_Reenter_Password'],
                        })}
                        type="password"
                        value={password2}
                    />
                    <Button
                        type="submit"
                        className={classNames(
                            utilStyles.submitButton,
                            utilStyles.pushTopXxl,
                            utilStyles.formButton
                        )}
                        disabled={disabled || !!validatePassword2(password1, password2)}
                        label={i18next.t('PERC_Submit', { defaultValue: enUS['PERC_Submit'] })}
                        variant="primary"
                    />
                </Form>
            </>
        )
    }
}

function validLength(s: string) {
    return s.length > 7
}

function containsUppercase(s: string) {
    return /[A-Z]+/g.test(s)
}

function containsLowercase(s: string) {
    return /[a-z]+/g.test(s)
}

function containsNumber(s: string) {
    return /\d+/g.test(s)
}

function containsSymbol(s: string) {
    return /[^a-zA-Z0-9]+/g.test(s)
}

export function validatePassword1(s: string): string | undefined {
    if (!validLength(s)) {
        return ERROR_MESSAGE_MAP[ERRORS.passwordTooShort]
    }
    if (!containsUppercase(s)) {
        return ERROR_MESSAGE_MAP[ERRORS.passwordMissingUppercase]
    }
    if (!containsLowercase(s)) {
        return ERROR_MESSAGE_MAP[ERRORS.passwordMissingLowercase]
    }
    if (!containsNumber(s)) {
        return ERROR_MESSAGE_MAP[ERRORS.passwordMissingNumber]
    }
    if (!containsSymbol(s)) {
        return ERROR_MESSAGE_MAP[ERRORS.passwordMissingSymbol]
    }
}

function passwordsMatch(a: string, b: string) {
    return isEmpty(validatePassword1(a)) && !isEmpty(b) && a === b
}

function validatePassword2(a: string, b: string): string | undefined {
    if (!passwordsMatch(a, b)) {
        return i18next.t('PERC_Pident_Password_Match', { defaultValue: enUS['PERC_Pident_Password_Match'] })
    }
}

export function ReqIcon({ password, validator }: { password: string; validator: (s: string) => boolean }) {
    if (isEmpty(password)) return <IconCircle size={14} className={utilStyles.reqPending} />
    if (validator(password)) return <IconCircleCheck size={14} className={utilStyles.reqSuccess} />
    return <IconCircleClose size={14} className={utilStyles.reqError} />
}

export function MatchIcon({ password1, password2 }: { password1: string; password2: string }) {
    if (isEmpty(password2)) return null
    if (passwordsMatch(password1, password2))
        return <IconCircleCheck size={14} className={utilStyles.matchSuccess} />
    return <IconCircleClose size={14} className={utilStyles.matchError} />
}
