import { Button } from '@percolate/ui'
import ModalCentered from 'apps/modal/centered'
import ModalDialog from 'apps/modal/dialog'
import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { Error } from 'lib/components/error'
import { RedirectToUrl } from 'lib/components/redirect_to_url'
import styles from 'lib/gdpr_consent.less'
import { IContext, IEventSubmitGdprConsent } from 'lib/typing'
import { isEmpty, isUndefined } from 'lodash'
import { Component } from 'react'
import { StateValue } from 'xstate'

export interface IProps {
    context: Pick<IContext, 'consentTerms' | 'error' | 'overrideSuccessRedirectUrl'>
    matches(stateValue: StateValue): boolean
    submitGdprConsent(e: IEventSubmitGdprConsent): void
}
interface IState {
    consent?: boolean
    showDenyConsentConfirmation: boolean
}

export class GdprConsent extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            showDenyConsentConfirmation: false,
        }
    }

    onChangeDenyConsent = () => {
        this.setState({ consent: false })
    }

    onChangeGiveConsent = () => {
        this.setState({ consent: true })
    }

    onClickDenyConsent = () => {
        this.setState({ showDenyConsentConfirmation: true })
    }

    onCloseModal = () => {
        this.setState({ showDenyConsentConfirmation: false })
    }

    onSubmitGdprConsent = () => {
        this.props.submitGdprConsent({
            type: 'submitGdprConsent',
            payload: {
                consent: this.state.consent!,
            },
        })
    }

    render() {
        const { context, matches } = this.props
        const disabled =
            matches({ gdprConsent: 'submittingGdprConsent' }) || matches({ gdprConsent: 'redirectingToApp' })
        if (this.state.showDenyConsentConfirmation) {
            return (
                <ModalDialog
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                className="push-right"
                                label={i18next.t('PERC_Cancel', { defaultValue: enUS['PERC_Cancel'] })}
                                onClick={this.onCloseModal}
                                variant="secondary"
                            />
                            <Button
                                disabled={disabled}
                                label={i18next.t('PERC_Pident_Delete_Account', {
                                    defaultValue: enUS['PERC_Pident_Delete_Account'],
                                })}
                                onClick={this.onSubmitGdprConsent}
                                variant="primary"
                            />
                        </div>
                    }
                    title={i18next.t('PERC_Pident_Withold_Consent', {
                        defaultValue: enUS['PERC_Pident_Withold_Consent'],
                    })}
                >
                    <p>
                        {i18next.t('PERC_Pident_Withold_Consent_Disable_Account', {
                            defaultValue: enUS['PERC_Pident_Withold_Consent_Disable_Account'],
                        })}
                    </p>
                    <p>
                        {i18next.t('PERC_Pident_Withold_Consent_Notify_Admin', {
                            defaultValue: enUS['PERC_Pident_Withold_Consent_Notify_Admin'],
                        })}
                    </p>
                </ModalDialog>
            )
        }
        return (
            <ModalCentered>
                <div className={styles.dialog}>
                    <div className={styles.header}>
                        {i18next.t('PERC_Pident_Privacy_Agreement', {
                            defaultValue: enUS['PERC_Pident_Privacy_Agreement'],
                        })}
                    </div>
                    <div className={styles.bodyOverflow}>
                        <div className={styles.body}>
                            <div className={styles.terms}>
                                <p>
                                    {i18next.t('PERC_Pident_GDPR_Consent', {
                                        defaultValue: enUS['PERC_Pident_GDPR_Consent'],
                                    })}
                                </p>
                                <ul>
                                    <li>
                                        {i18next.t('PERC_Pident_GDPR_Written_Consent', {
                                            defaultValue: enUS['PERC_Pident_GDPR_Written_Consent'],
                                        })}
                                    </li>
                                    <li>
                                        {i18next.t('PERC_Pident_GDPR_Written_Consent_Data', {
                                            defaultValue: enUS['PERC_Pident_GDPR_Written_Consent_Data'],
                                        })}
                                    </li>
                                    <li>
                                        <a href="https://percolate.com/privacy" target="blank">
                                            {i18next.t('PERC_Pident_Privacy_Policy', {
                                                defaultValue: enUS['PERC_Pident_Privacy_Policy'],
                                            })}
                                        </a>
                                    </li>
                                </ul>
                                {!isEmpty(context.consentTerms) && (
                                    <div dangerouslySetInnerHTML={{ __html: context.consentTerms! }} />
                                )}
                            </div>
                            <div className={styles.toggle}>
                                <div className={styles.radio}>
                                    <label>
                                        <input
                                            type="radio"
                                            onChange={this.onChangeGiveConsent}
                                            checked={this.state.consent === true}
                                        />
                                        {i18next.t('PERC_Pident_Agree_Terms', {
                                            defaultValue: enUS['PERC_Pident_Agree_Terms'],
                                        })}
                                    </label>
                                </div>
                                <div className={styles.radio}>
                                    <label>
                                        <input
                                            type="radio"
                                            onChange={this.onChangeDenyConsent}
                                            checked={this.state.consent === false}
                                        />
                                        {i18next.t('PERC_Pident_Disagree_Terms', {
                                            defaultValue: enUS['PERC_Pident_Disagree_Terms'],
                                        })}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        {matches({ gdprConsent: 'redirectingToApp' }) && (
                            <RedirectToUrl url={context.overrideSuccessRedirectUrl!} />
                        )}
                        {context.error && <Error error={context.error} />}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {this.state.consent === false ? (
                                <Button
                                    label={i18next.t('PERC_Pident_Delete_Account', {
                                        defaultValue: enUS['PERC_Pident_Delete_Account'],
                                    })}
                                    onClick={this.onClickDenyConsent}
                                    variant="primary"
                                />
                            ) : (
                                <Button
                                    disabled={disabled || isUndefined(this.state.consent)}
                                    label={i18next.t('PERC_Continue', {
                                        defaultValue: enUS['PERC_Continue'],
                                    })}
                                    onClick={this.onSubmitGdprConsent}
                                    variant="primary"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ModalCentered>
        )
    }
}
