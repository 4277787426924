import i18next from 'env/i18n-config'
import enUS from 'i18n/en_US'
import { RedirectToSaml2 } from 'lib/components/redirect_to_saml2'
import { Typography } from 'lib/components/typography'
import { IContext } from 'lib/typing'

export interface IProps {
    context: Pick<IContext, 'saml2IdpUri' | 'saml2Request' | 'saml2Token'>
}

export function RedirectingToSaml2({ context }: IProps) {
    return (
        <>
            <Typography align="center" type="header1">
                {i18next.t('PERC_Redirecting', { defaultValue: enUS['PERC_Redirecting'] })}
            </Typography>
            <RedirectToSaml2
                saml2IdpUri={context.saml2IdpUri!}
                saml2Request={context.saml2Request!}
                saml2Token={context.saml2Token!}
            />
        </>
    )
}
