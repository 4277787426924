/* eslint react/no-string-refs: "warn" */
import { IconClose } from '@percolate/ui'
import ModalCentered from 'apps/modal/centered'
import { widths as WIDTHS } from 'apps/modal/constants'
import styles from 'apps/modal/less/dialog.less'
import classnames from 'classnames'
import { map } from 'lodash-es'
import PropTypes from 'prop-types'
import { Component } from 'react'

export default class ModalDialog extends Component {
    static propTypes = {
        aside: PropTypes.any,
        asideFooter: PropTypes.any,
        asideRight: PropTypes.bool,
        children: PropTypes.any,
        className: PropTypes.string,
        footer: PropTypes.any,
        isFixedHeight: PropTypes.bool,
        noMaxHeight: PropTypes.bool,
        onClose: PropTypes.func,
        title: PropTypes.string.isRequired,
        width: PropTypes.oneOf(map(WIDTHS, 'id')),
        noBackground: PropTypes.bool,
    }

    static defaultProps = {
        isFixedHeight: false,
    }

    asideRefFn = node => (this.aside = node)

    render() {
        const main = (
            <div className={styles.main} ref="main">
                <div
                    className={classnames(styles.content, {
                        [styles.fixedHeight]: this.props.isFixedHeight,
                        [styles.noMaxHeight]: this.props.noMaxHeight,
                    })}
                >
                    {this.props.children}
                </div>
                {this.props.footer && (
                    <div className={styles.footer} ref="footer">
                        {this.props.footer}
                    </div>
                )}
            </div>
        )
        return (
            <ModalCentered onClose={this.props.onClose} noBackground={this.props.noBackground}>
                <div className={classnames(styles.dialog, this.props.className, styles[this.props.width])}>
                    <div className={styles.header}>
                        {this.props.onClose ? (
                            <IconClose className={styles.closeIcon} size={20} onClick={this.props.onClose} />
                        ) : null}
                        <span className={styles.title}>{this.props.title}</span>
                    </div>
                    <div className={styles.body}>
                        {this.props.aside && !this.props.asideRight && (
                            <div className={styles.aside} ref={this.asideRefFn}>
                                {this.props.aside}
                                {this.props.asideFooter && (
                                    <div className={styles.footer} ref="footer">
                                        {this.props.asideFooter}
                                    </div>
                                )}
                            </div>
                        )}
                        {main}
                        {this.props.aside && this.props.asideRight && (
                            <div className={classnames(styles.aside, styles.right)} ref={this.asideRefFn}>
                                {this.props.aside}
                                {this.props.asideFooter && (
                                    <div className={styles.footer} ref="footer">
                                        {this.props.asideFooter}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ModalCentered>
        )
    }
}
